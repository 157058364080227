import React from "react"
import ActionPanel from "../../components/ActionPanel";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import {Table, Modal, Icon, Form, Comment, Loader, Label, Button as SemanticButton} from "semantic-ui-react";
import Button from "../../components/Button";
import {OrderStatusesHelper} from "../../helpers/orderStatuses";
import {api, UPLOADS_URL} from "../../lib/axios";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PaperTitle from "../../components/PaperTitle";
import DataPresentation from "../../components/DataPresentation";
import OrderStatus from "../orders/OrderStatus";
import {formatDate} from "../../helpers/dateHelper";
import EsotiqInput from "../../components/EsotiqInput";
import SnackBar from "../../components/Snack";
import {DATA_OBJECT} from "../../controller/dataController";
import {TranslationController} from "../../controller/menager";
import {copyToClipboard} from "../../lib/globals";
import Confirm from "../../components/Confirm";
import {PDFDownloadLink} from "@react-pdf/renderer";
import PDFRevert from "./PDFRevert";
import PDFComplaint from "./PDFComplaint";

export default class OnlineComplaintListWarehouseInside extends React.Component {
    constructor(props) {
        super(props);

        this.downloadLink = {}

        this.state = {
            generatedPdf: {},
            isLoading: false,
            results: [],
            value: ''
        }
    }

    async componentDidMount() {
        api().post("/api/complaints/getByNo", {complaintNo: this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 1]})
            .then(r => {
                return r.data.data[0];
            })
            .then((data) => {
                this.state.data = data;
                this.state.fullComplaint = data;

                if (!data.revertData) {
                    return api().post("/api/complaints/getRevertProdcuts", {invoiceNo: data.invoiceNo})
                } else {
                    this.setState({
                        refresh: new Date().getTime()
                    })
                }
            })
            .then((result) => {
                if (result && result.data) {
                    if (result.data.error) {
                        return SnackBar(result.data.message, "error")
                    }

                    this.state.data.revertData = result.data.data;

                    this.setState({
                        refresh: new Date().getTime()
                    })
                }
            })
    }

    changeStatus() {
        if (this.state.selectedStatus.status === "complaintRejected") {
            if (!this.state.rejectedReason) {
                return SnackBar("Nie podałeś powodu odrzucenia.", "error")
            }

            if (this.state.rejectedReason.length < 4) {
                return SnackBar("Wprowadzony tekst jest za krótki.", "error")
            }
        }
        const complaint = this.state.data

        let historyValue = "Zmieniono status zgłoszenia z " + new OrderStatusesHelper().getLabel(complaint.status) + " na " + new OrderStatusesHelper().getLabel(this.state.selectedStatus.status)

        if (this.state.rejectedReason) {
            historyValue = historyValue + ". Jako powód podano: " + this.state.rejectedReason
        }

        complaint.history.push({
            value: historyValue, timestamp: new Date().getTime(), subValue: DATA_OBJECT["user"].login
        })
        complaint.status = this.state.selectedStatus.status
        complaint.rejectedReason = this.state.rejectedReason

        let layout = null

        switch (complaint.status) {
            case "complaintNew":
                layout = "complaint/new"
                break
            case "complaintProgress":
                layout = "complaint/in_progress"
                break
            case "complaintAccepted":
                layout = "complaint/approved"
                break
            case "complaintRejected":
                layout = "complaint/rejected"
                break
        }

        api().post("/api/mailer/sendExternalMail",
            {
                orderId: complaint.orderId,
                variant: layout,
                userId:  DATA_OBJECT.user_login,
                additionalData: complaint
            })
            .then(result => {
                if (result.data.success) {
                    SnackBar("Wysłano powiadomienie e-mail");
                } else {
                    SnackBar("Błąd podczas wysyłania powiadomienia. Sprawdź konfigurację.", "error");
                }
            })

        api().post("/api/complaints/update", {complaint})
            .then(() => {
                this.setState({
                    changeStatus: null,
                    selectedStatus: null,
                    rejectedReason: null
                })

                SnackBar("Pomyślnie zaktualizowano.")
            })
    }

    addComment() {
        if (!this.state.newCommentValue) {
            return SnackBar("Wprowadzona wartość nie może być pusta.", "error")
        }

        if (this.state.newCommentValue.length < 4) {
            return SnackBar("Wprowadzony tekst jest za krótki.", "error")
        }

        const complaint = this.state.data

        if (!complaint.comments) {
            complaint.comments = []
        }

        complaint.comments.push({
            value: this.state.newCommentValue,
            timestamp: new Date().getTime(),
            user: DATA_OBJECT.user_login,
            orderId: complaint.orderId
        })

        api().post("/api/orders/comments/add", {
            value: this.state.newCommentValue,
            timestamp: new Date().getTime(),
            user: DATA_OBJECT.user_login,
            orderId: complaint.orderId,
            isRevertComment: true
        })
            .then(() => {
                this.setState({
                    newCommentValue: "",
                })

                SnackBar("Pomyślnie zaktualizowano.")
            })
    }

    saveSwitch() {
        const complaint = this.state.data

        complaint.revertData = this.state.switchProducts
        complaint.history.push({
            value: "Zmieniono wartość produktów w ramach zgłoszenia", timestamp: new Date().getTime()
        })

        this.setState({
            refresh: new Date().getTime(),
            switchProducts: null
        })

        api().post("/api/complaints/update", {complaint})
            .then(() => {
                SnackBar("Pomyślnie zaktualizowano.")
            })
    }

    removeComment(comment) {
        if (DATA_OBJECT.user_login !== comment.user) {
            return SnackBar("Możesz usuwać tylko własne komenatrze", "error")
        }

        const complaint = this.state.data

        complaint.comments = complaint.comments.filter(comm => comm !== comment)

        api().post("/api/orders/comments/remove", {...comment})
            .then(() => {
                this.setState({
                    refresh: new Date().getTime(),
                })

                SnackBar("Pomyślnie zaktualizowano.")
            })
    }

    jsonCopy(src) {
        return JSON.parse(JSON.stringify(src));
    }

    switchProduct(product, key, qty) {
        if (key === "new") {
            const isProduct = this.state.switchProducts.find(p => p.sku === product.sku)

            if (isProduct) {
                const orderItem = this.state.data.orderData.items.find(p => p.sku === product.sku)

                if (isProduct.qty + 1 > orderItem.qty) {
                    return SnackBar("Wybrana ilość produktów przekracza faktyczną ilość towaru w zamówieniu.", "error")
                }

                isProduct.qty = isProduct.qty + 1
                isProduct.revertQty = isProduct.revertQty + 1

                this.setState({
                    refresh: new Date().getTime(),
                    productSwitchAddNew: null
                })
            } else {
                const originalProduct = this.state.data.orderData.items.find(p => p.sku === product.sku);

                this.state.switchProducts.push({
                    complaintForm: null,
                    files: null,
                    name: product.name,
                    qty: 1,
                    revertAdditionalInfo: originalProduct.revertAdditionalInfo,
                    revertData: originalProduct.revertData,
                    revertOrderType: originalProduct.revertOrderType,
                    revertProduct: product,
                    revertQty: 1,
                    revertReason: originalProduct.revertReason,
                    revertType: originalProduct.revertType,
                    sku: product.sku,
                })
            }

            this.setState({
                refresh: new Date().getTime(),
                productSwitchAddNew: null
            })
        } else {
            this.state.switchProducts[key].name = product.name
            this.state.switchProducts[key].sku = product.sku
            this.state.switchProducts[key].revertProduct = product

            this.setState({
                refresh: new Date().getTime(),
                switchProductSelected: null,
                search: null
            })
        }
    }

    switchProductDelete(index) {
        this.state.switchProducts = this.state.switchProducts.filter((p, k) => k !== index)
        this.setState({
            refresh: new Date().getTime()
        })
    }

    getStoreProducts() {
        this.setState({
            storeProducts: this.state.data.orderData.items
        })
    }

    searchResultSelect(e, {result}) {
        const product = this.state.storeProducts.find(p => p.sku === result.title)

        this.setState({
            variantSelect: {
                sku: result.title,
                variants: product.variants,
                product: product
            }
        })
    }

    approveSwitchProduct(sku) {
        this.state.data.revertData.forEach(item => {
            if (item.sku == sku) {
            }
        })
    }

    searchChange(e, {value}) {
        this.setState({ value, results: [], variantSelect: null, switchProductVariant: null, switchProductProduct: null })

        if (value && value.length > 2) {
            setTimeout(() => {
                this.setState({ isLoading: true, results: [] })

                setTimeout(() => {
                    const items = []

                    for (let i = 0; i < this.state.storeProducts.length; i++) {
                        if (this.state.storeProducts[i].sku.includes(value)) {
                            items.push({
                                title: this.state.storeProducts[i].sku,
                                description: this.state.storeProducts[i].name,
                                image: (this.state.storeProducts[i].images[0] ? this.state.storeProducts[i].images[0].file : null),
                            })
                        }
                    }

                    this.setState({
                        isLoading: false,
                        results: items
                    })
                }, 300)
            }, 500)
        }
    }

    update() {
        api().post("/api/complaints/update", {complaint: this.state.data})
            .then(() => {
                this.setState({
                    changeStatus: null,
                    selectedStatus: null,
                    rejectedReason: null
                })

                SnackBar("Pomyślnie zaktualizowano.")
            })
    }

    updateOrderId() {
        api().post("/api/orders/get", {orderId: this.state.newOrderId})
            .then(res => {
                if (!res.data.success) {
                    return SnackBar("Nie znaleziono zamówienia", "error");
                }

                for (let revertItem of this.state.data.revertData) {
                    const orderItem = res.data.order.items.find(i => i.sku === revertItem.sku);

                    if (!orderItem) {
                        return SnackBar("Nie znaleziono produktu w zamówieniu", "error");
                    }

                    if (parseInt(orderItem.qty) < parseInt(revertItem.revertQty)) {
                        return SnackBar("Ilość produktu w zamówieniu jest mniejsza niż ilość w zgłoszeniu", "error");
                    }
                }

                this.setState({
                    data: {
                        ...this.state.data,
                        orderData: res.data.order,
                        orderId: this.state.newOrderId
                    }
                })

                this.update();
            });
    }

    render() {
        const {data, isLoading, value, results} = this.state;

        return (
            <React.Fragment>
                <ActionPanel
                    actions={[]}
                />

                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: "Podgląd zgłoszenia"
                            }
                        ]}
                    />

                    <div style={{height: 10}}></div>

                    {data ?
                    <React.Fragment>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Paper>
                                    <PaperTitle title={"Dane podstawowe"}/>

                                    <div style={{display: "flex"}}>
                                        <Button
                                            style={{
                                                marginBottom: 5,
                                                marginRight: 5,
                                                position: "relative",
                                                top: -10
                                            }}
                                            onClick={() => {
                                                if (this.state.isOrderIdEditableState) {
                                                    return SnackBar("Zapisz nr zamówienia przed edycją.", "error");
                                                };

                                                if (this.state.isEditableState) {
                                                    this.update();

                                                    this.setState({
                                                        isEditableState: false
                                                    })
                                                } else {
                                                    this.setState({
                                                        isEditableState: true
                                                    })
                                                }
                                            }}
                                        >
                                            {this.state.isEditableState ? "Zapisz" : "Edycja"}
                                        </Button>
                                        <Button
                                            style={{
                                                marginBottom: 5,
                                                marginRight: 5,
                                                position: "relative",
                                                top: -10
                                            }}
                                            onClick={() => {
                                                if (this.state.isEditableState) {
                                                    return SnackBar("Zapisz zmiany przed edycją nr zamówienia.", "error");
                                                }

                                                if (this.state.isOrderIdEditableState) {
                                                    this.updateOrderId();

                                                    this.setState({
                                                        isOrderIdEditableState: false
                                                    })
                                                } else {
                                                    this.setState({
                                                        isOrderIdEditableState: true
                                                    })
                                                }
                                            }}
                                        >
                                            {this.state.isOrderIdEditableState ? "Zapisz" : "Edycja nr zamówienia"}
                                        </Button>

                                        <div
                                            style={{
                                                position: "relative",
                                                top: -10
                                            }}
                                        >
                                            {this.state.fullComplaint && this.state.fullComplaint.revertType.includes("_") ?
                                                <div>
                                                    <div style={{marginBottom: 10}}>
                                                        {
                                                            this.state.generatedPdf[this.state.fullComplaint.orderData.orderId + "_z"] ?
                                                                <PDFDownloadLink ref={e => this.downloadLink = e}
                                                                                 document={<PDFRevert data={this.state.fullComplaint}/>}
                                                                                 fileName={`Formularz zwrotu_${this.state.fullComplaint.orderId}`}>
                                                                    {({blob, url, loading, error}) => (loading ? <Button
                                                                        color='blue'
                                                                        loading={true}
                                                                        label={{
                                                                            basic: true,
                                                                            color: 'blue',
                                                                            pointing: 'left',
                                                                            content: '.pdf'
                                                                        }}
                                                                    /> : <div>
                                                                        <Button
                                                                            color='blue'
                                                                            content='Pobierz'
                                                                            icon='download'
                                                                            label={{
                                                                                basic: true,
                                                                                color: 'blue',
                                                                                pointing: 'left',
                                                                                content: '.pdf'
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();

                                                                                window.open(url, '_blank')

                                                                                return true
                                                                            }}
                                                                        />
                                                                    </div>)}
                                                                </PDFDownloadLink> :
                                                                <Button
                                                                    color='blue'
                                                                    content='Generuj formularz zwrotu'
                                                                    icon='download'
                                                                    label={{
                                                                        basic: true,
                                                                        color: 'blue',
                                                                        pointing: 'left',
                                                                        content: '.pdf'
                                                                    }}
                                                                    onClick={() => {
                                                                        this.state.generatedPdf[this.state.fullComplaint.orderData.orderId + "_z"] = true

                                                                        this.setState({
                                                                            refresh: new Date().getTime()
                                                                        })
                                                                    }}
                                                                >

                                                                </Button>
                                                        }
                                                    </div>

                                                    <div>
                                                        {
                                                            this.state.generatedPdf[this.state.fullComplaint.orderData.orderId + "_r"] ?
                                                                <PDFDownloadLink ref={e => this.downloadLink = e}
                                                                                 document={<PDFComplaint complaintReasons={this.state.complaintReasons} data={this.state.fullComplaint}/>}
                                                                                 fileName={`Formularz reklamacji_${this.state.fullComplaint.orderId}`}>
                                                                    {({blob, url, loading, error}) => (loading ? <Button
                                                                        color='blue'
                                                                        loading={true}
                                                                        label={{
                                                                            basic: true,
                                                                            color: 'blue',
                                                                            pointing: 'left',
                                                                            content: '.pdf'
                                                                        }}
                                                                    /> : <div>
                                                                        <Button
                                                                            color='blue'
                                                                            content='Pobierz'
                                                                            icon='download'
                                                                            label={{
                                                                                basic: true,
                                                                                color: 'blue',
                                                                                pointing: 'left',
                                                                                content: '.pdf'
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();

                                                                                window.open(url, '_blank')

                                                                                return true
                                                                            }}
                                                                        />
                                                                    </div>)}
                                                                </PDFDownloadLink> :
                                                                <Button
                                                                    color='blue'
                                                                    content='Generuj formularz reklamacji'
                                                                    icon='download'
                                                                    label={{
                                                                        basic: true,
                                                                        color: 'blue',
                                                                        pointing: 'left',
                                                                        content: '.pdf'
                                                                    }}
                                                                    loading={this.state.loader || false}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            loader: true
                                                                        })

                                                                        api(true).post("/api/globalConfigs/get", {config: "complaintReasons"})
                                                                            .then(resp => {
                                                                                this.state.generatedPdf[this.state.fullComplaint.orderData.orderId + "_r"] = true

                                                                                this.setState({
                                                                                    complaintReasons: resp.data.data,
                                                                                    loader: false
                                                                                })
                                                                            })
                                                                    }}
                                                                >

                                                                </Button>
                                                        }
                                                    </div>
                                                </div>

                                                : this.state.fullComplaint.revertType === "revert" ?
                                                    <div>
                                                        {
                                                            this.state.generatedPdf[this.state.fullComplaint.orderData.orderId] ?
                                                                <PDFDownloadLink ref={e => this.downloadLink = e}
                                                                                 document={<PDFRevert data={this.state.fullComplaint}/>}
                                                                                 fileName={`Formularz zwrotu_${this.state.fullComplaint.orderId}`}>
                                                                    {({blob, url, loading, error}) => (loading ? <Button
                                                                        color='blue'
                                                                        loading={true}
                                                                        label={{
                                                                            basic: true,
                                                                            color: 'blue',
                                                                            pointing: 'left',
                                                                            content: '.pdf'
                                                                        }}
                                                                    /> : <div>
                                                                        <Button
                                                                            color='blue'
                                                                            content='Pobierz'
                                                                            icon='download'
                                                                            label={{
                                                                                basic: true,
                                                                                color: 'blue',
                                                                                pointing: 'left',
                                                                                content: '.pdf'
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();

                                                                                window.open(url, '_blank')

                                                                                return true
                                                                            }}
                                                                        />
                                                                    </div>)}
                                                                </PDFDownloadLink> :
                                                                <Button
                                                                    color='blue'
                                                                    content='Generuj formularz zwrotu'
                                                                    icon='download'
                                                                    label={{
                                                                        basic: true,
                                                                        color: 'blue',
                                                                        pointing: 'left',
                                                                        content: '.pdf'
                                                                    }}
                                                                    onClick={() => {
                                                                        this.state.generatedPdf[this.state.fullComplaint.orderData.orderId] = true

                                                                        this.setState({
                                                                            refresh: new Date().getTime()
                                                                        })
                                                                    }}
                                                                >

                                                                </Button>
                                                        }
                                                    </div> :
                                                    <div>
                                                        {
                                                            this.state.generatedPdf[this.state.fullComplaint.orderData.orderId] ?
                                                                <PDFDownloadLink ref={e => this.downloadLink = e}
                                                                                 document={<PDFComplaint complaintReasons={this.state.complaintReasons} data={this.state.fullComplaint}/>}
                                                                                 fileName={`Formularz reklamacji_${this.state.fullComplaint.orderId}`}>
                                                                    {({blob, url, loading, error}) => (loading ? <Button
                                                                        color='blue'
                                                                        loading={true}
                                                                        label={{
                                                                            basic: true,
                                                                            color: 'blue',
                                                                            pointing: 'left',
                                                                            content: '.pdf'
                                                                        }}
                                                                    /> : <div>
                                                                        <Button
                                                                            color='blue'
                                                                            content='Pobierz'
                                                                            icon='download'
                                                                            label={{
                                                                                basic: true,
                                                                                color: 'blue',
                                                                                pointing: 'left',
                                                                                content: '.pdf'
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();

                                                                                window.open(url, '_blank')

                                                                                return true
                                                                            }}
                                                                        />
                                                                    </div>)}
                                                                </PDFDownloadLink> :
                                                                <Button
                                                                    color='blue'
                                                                    content='Generuj formularz reklamacji'
                                                                    icon='download'
                                                                    label={{
                                                                        basic: true,
                                                                        color: 'blue',
                                                                        pointing: 'left',
                                                                        content: '.pdf'
                                                                    }}
                                                                    loading={this.state.loader || false}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            loader: true
                                                                        })

                                                                        api(true).post("/api/globalConfigs/get", {config: "complaintReasons"})
                                                                            .then(resp => {
                                                                                this.state.generatedPdf[this.state.fullComplaint.orderData.orderId] = true

                                                                                this.setState({
                                                                                    complaintReasons: resp.data.data,
                                                                                    loader: false
                                                                                })
                                                                            })
                                                                    }}
                                                                >

                                                                </Button>
                                                        }
                                                    </div>}
                                        </div>
                                    </div>

                                    <Grid container spacing={2}>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    label={"Nr zgłoszenia"}
                                                    data={data.complaintNo}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                {
                                                    this.state.isEditableState
                                                        ? <EsotiqInput
                                                            label={"Typ"}
                                                            options={
                                                                [
                                                                    {key: "complaint", text: "Reklamacja", value: "complaint"},
                                                                    {key: "revert", text: "Zwrot", value: "revert"},
                                                                ]
                                                            }
                                                            onChange={e => data.revertType = e}
                                                        />
                                                        : <DataPresentation
                                                            label={"Typ"}
                                                            data={data.revertType === "complaint" ? "Reklamacja" : data.revertType === "revert" ? "Zwrot" : "Zwrot + reklamacja"}
                                                        />
                                                }
                                                
                                                
                                            </Grid>
                                        </Grid>

                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                {/*<div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>*/}
                                                {/*    <div style={{width: "100%"}}>*/}
                                                {/*        <OrderStatus*/}
                                                {/*            bg={new OrderStatusesHelper().getColor(data.status)}*/}
                                                {/*            font={new OrderStatusesHelper().getFontColor(data.status)}*/}
                                                {/*            label={new OrderStatusesHelper().getLabel(data.status)}*/}
                                                {/*            status={"Status"}*/}
                                                {/*        />*/}
                                                {/*    </div>*/}

                                                {/*    <Button*/}
                                                {/*        icon*/}
                                                {/*        onClick={() => this.setState({*/}
                                                {/*            changeStatus: true*/}
                                                {/*        })}*/}
                                                {/*    >*/}
                                                {/*        <Icon name='edit'/>*/}
                                                {/*    </Button>*/}
                                                {/*</div>*/}

                                                <div>
                                                    <SemanticButton.Group>
                                                        {[
                                                            {status: "complaintNew", label: "Nowe"},
                                                            {status: "complaintProgress", label: "W toku"},
                                                            {status: "complaintAccepted", label: "Uznane"},
                                                            {status: "complaintRejected", label: "Odrzucone"}
                                                        ].map((e, k) => {
                                                            return (
                                                                <SemanticButton
                                                                    active={data.status === e.status}
                                                                    key={k}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            selectedStatus: {
                                                                                status: e.status
                                                                            }
                                                                        }, () => {
                                                                            if (this.state.selectedStatus && (this.state.selectedStatus.status === "complaintRejected" || this.state.selectedStatus.status === "complaintAccepted")) {
                                                                                this.setState({
                                                                                    changeStatus: true
                                                                                })
                                                                            } else {
                                                                                Confirm("Czy na pewno chcesz zmienić status?", null, () => {
                                                                                    this.changeStatus();
                                                                                });
                                                                            }
                                                                        })
                                                                    }}
                                                                >{e.label}
                                                                </SemanticButton>
                                                            )
                                                        })}
                                                    </SemanticButton.Group>
                                                </div>

                                                {data.rejectedReason &&
                                                <DataPresentation
                                                    label={data.status === "complaintAccepted" ? "Powód akceptacji" : "Powód odrzucenia"}
                                                    data={data.rejectedReason}
                                                />
                                                }
                                            </Grid>

                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    label={"Zgłoszono w"}
                                                    data={data.revertSource}
                                                    copy={true}
                                                    onChange={e => data.revertSource = e}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    label={"Dotyczy sklepu"}
                                                    data={data.source}
                                                    onChange={e => data.source = e}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    disabled={true}
                                                    label={"Dotyczy zamówienia"}
                                                    data={data.orderId}
                                                    onChange={e => this.state.newOrderId = e}
                                                    editable={this.state.isOrderIdEditableState}
                                                    icon={"search"}
                                                    copy={true}
                                                    selfEditable={true}
                                                    onIconClick={() => {
                                                        window.open("#/order/" + data.orderId, '_blank')
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    label={"Zamawiający"}
                                                    data={data.orderData.addressShipping.firstname}
                                                    onChange={e => data.orderData.addressShipping.firstname = e}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    label={"Email"}
                                                    data={data.orderData.addressShipping.email}
                                                    onChange={e => data.orderData.addressShipping.email = e}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    label={"Telefon"}
                                                    data={data.orderData.customerPhone}
                                                    onChange={e => data.orderData.customerPhone = e}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    onChange={e => {
                                                        data.revertCustomerData.revertBankName = e;
                                                    }}
                                                    label={"Nazwa banku"}
                                                    data={data.revertCustomerData ? data.revertCustomerData.revertBankName : "---"}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container xs={12}>
                                            <Grid item xs={12}>
                                                <DataPresentation
                                                    editable={this.state.isEditableState}
                                                    onChange={e => {
                                                        data.revertCustomerData.revertBankAccount = e;
                                                    }}
                                                    label={"Numer konta"}
                                                    data={data.revertCustomerData ? data.revertCustomerData.revertBankAccount : "---"}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>

                                <Paper>
                                    <PaperTitle title={"Historia"}/>
                                    <div style={{overflowY: "scroll", maxHeight: "400px"}}>
                                    {data.history ?
                                        data.history.sort((a, b) => b.timestamp - a.timestamp).map((history, key) => {
                                            return (
                                                <div className={"complaint-preview-history-box"} key={key} >
                                                    <div className={"complaint-preview-history-box-icon"}>
                                                        <Icon name={"caret right"}/>
                                                    </div>

                                                    <div className={"complaint-preview-history-box-data"}>
                                                        <div className={"complaint-preview-history-box-data-date"}>{formatDate(history.timestamp).dateTimeSystem}</div>
                                                        <div className={"complaint-preview-history-box-data-value"}>
                                                            {history.subValue &&
                                                                <span>({history.subValue})</span>
                                                            }

                                                            {history.value}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : <div style={{
                                            textAlign: "center",
                                            color: "#c6c6c6",
                                            margin: 20
                                        }}>Brak danych</div>}
                                    </div>
                                </Paper>
                            </Grid>

                            <Grid item xs={6}>
                                {data.revertFiles &&
                                    <Paper>
                                        <PaperTitle title={"Załączniki"}/>

                                        {data.revertFiles.map((e, key) => {
                                            return (
                                                <div className={"complaint-preview-history-box"} key={key}>
                                                    <div className={"complaint-preview-history-box-icon"}>
                                                        <Icon name={"caret right"}/>
                                                    </div>

                                                    <div className={"complaint-preview-history-box-data"}>
                                                        {/*<div className={"complaint-preview-history-box-data-date"}>Plik</div>*/}
                                                        {/*<div className={"complaint-preview-history-box-data-value"}>*/}
                                                        {/*    {e.filename}*/}
                                                        {/*</div>*/}

                                                        <Button as='div' labelPosition='right'
                                                            onClick={() => {
                                                                window.open(`${UPLOADS_URL}${e.filename}`);
                                                            }}
                                                        >
                                                            <Button basic color='blue'>
                                                                <Icon name='download' />
                                                                Plik
                                                            </Button>
                                                            <Label as='a' basic color='blue' pointing='left'>
                                                                Otwórz
                                                            </Label>
                                                        </Button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Paper>
                                }

                                <Paper>
                                    <PaperTitle title={"Produkty w ramach zgłoszenia"}/>

                                    <Table
                                        basic='very'
                                    >
                                        <Table.Header>
                                            <Table.Row className={"ui-table-tr-background"}>
                                                <Table.HeaderCell>SKU</Table.HeaderCell>
                                                <Table.HeaderCell>Produkt</Table.HeaderCell>
                                                <Table.HeaderCell>Ilość</Table.HeaderCell>
                                                <Table.HeaderCell>Wartość</Table.HeaderCell>
                                                <Table.HeaderCell>{(data.revertData && data.revertData[0].revertType) === "complaint" ? "Forma reklamacji" : "Powód zwrotu"} (Opis)</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {data.revertData && data.revertData.map((item, index) => {
                                                if (item.revertTok99) {
                                                    data.revertToK99 = true
                                                }

                                                return (
                                                    <Table.Row key={index}
                                                               className={"ui-table-tr-background"}>
                                                        <Table.Cell
                                                            style={{
                                                                cursor: "pointer"
                                                            }}
                                                            className={"hover-lb"}
                                                            onClick={() => {
                                                                copyToClipboard(item.sku);
                                                            }}
                                                        >
                                                            <div>
                                                                {item.sku} <Icon name={"copy"}/>
                                                            </div>
                                                            {item.revertTok99 &&
                                                            <div style={{
                                                                fontSize: "0.85em",
                                                                color: "#ff4a2d",
                                                                fontWeight: 600
                                                            }}>Towar odeślij na mag. K99</div>
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {item.name}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {item.revertQty}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {parseFloat((item.revertProduct.finalQtyPrice/item.revertProduct.qty).toFixed(2) * item.revertQty)}
                                                            <span> {data.orderData.currency ? data.orderData.currency.toUpperCase() : ""}</span>
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            style={{
                                                                width: 300
                                                            }}
                                                        >
                                                            {data.revertData[0].revertType === "complaint" ? item.complaintForm : item.revertReason}

                                                            <div style={{fontSize: "1em", color: "#6c6c6c"}}>{item.revertAdditionalInfo}</div>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            })}

                                            <Table.Row>
                                                <Table.Cell/>
                                                <Table.Cell/>
                                                <Table.Cell
                                                    style={{
                                                        fontWeight: 600
                                                    }}
                                                >
                                                    {data.revertData ?
                                                        (() => {
                                                            let sum = 0;

                                                            data.revertData.map(e => sum += e.revertQty);

                                                            return sum;
                                                        })()
                                                        : null
                                                    }
                                                </Table.Cell>
                                                <Table.Cell
                                                    style={{
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {data.revertData ?
                                                        (() => {
                                                            let sum = 0;

                                                            data.revertData.map(item => {
                                                                // const orderItem = this.state.data.orderData.items.find(e => e.sku === item.sku);
                                                                const eachPrice = parseFloat((item.revertProduct.finalQtyPrice / item.revertProduct.qty) * item.revertQty);

                                                                sum += eachPrice;
                                                            });

                                                            return `${sum.toFixed(2)} ${data.orderData.currency}`;
                                                        })()
                                                        : null
                                                    }
                                                </Table.Cell>
                                                <Table.Cell/>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>

                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center"
                                    }}>
                                        {data.revertData &&
                                        <div style={{
                                            marginRight: 10
                                        }}>
                                            <div>
                                                <span>{TranslationController.translate("Edycja")}: </span>
                                                {/*<span>{data.revertData.reduce((prevValue, currentValue) => {*/}
                                                {/*    return prevValue + parseFloat(currentValue.revertProduct.price * currentValue.revertQty);*/}
                                                {/*}, 0).toFixed(2)} {data.orderData.currency ? data.orderData.currency.toUpperCase() : ""}</span>*/}
                                            </div>
                                        </div>
                                        }

                                        <Button
                                            icon
                                            onClick={() => {
                                                this.getStoreProducts()

                                                this.setState({
                                                    switchProducts: this.jsonCopy(data.revertData)
                                                })
                                            }}
                                        >
                                            <Icon name='edit'/>
                                        </Button>
                                    </div>
                                </Paper>

                                <Paper>
                                    <PaperTitle title={"Korekty"}/>

                                    <div>
                                        <Table
                                            basic='very'
                                        >
                                            <Table.Header>
                                                <Table.Row className={"ui-table-tr-background"}>
                                                    <Table.HeaderCell>Nr korekty</Table.HeaderCell>
                                                    <Table.HeaderCell>Wartość</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                <>
                                                {(data.erpData && data.k2Correction) && data.erpData.map((item, index) => {
                                                    return (
                                                        <Table.Row key={index} className={"ui-table-tr-background"}>
                                                            <Table.Cell
                                                                width={1}
                                                                style={{
                                                                    cursor: "pointer"
                                                                }}
                                                                className={"hover-lb"}
                                                            >
                                                                <a href={item.pdf}>{item.invoiceNumber}</a>
                                                            </Table.Cell>
                                                            <Table.Cell
                                                                style={{
                                                                    cursor: "pointer"
                                                                }}
                                                                className={"hover-lb"}
                                                                onClick={() => copyToClipboard(item.invoiceCorrectionAmount)}
                                                            >
                                                                <Icon name={"copy"}/>
                                                                {parseFloat(item.invoiceCorrectionAmount).toFixed(2)}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )
                                                })}
                                                </>
                                                <>
                                                {(data.erpData && !data.k2Correction) && data.erpData.map((item, index) => {
                                                    return (
                                                        <Table.Row key={index} className={"ui-table-tr-background"}>
                                                            <Table.Cell
                                                                width={1}
                                                                style={{
                                                                    cursor: "pointer"
                                                                }}
                                                                className={"hover-lb"}
                                                                onClick={() => {
                                                                    if (item.numer) {
                                                                        api().post("/api/orders/downloadInvoice",
                                                                            {
                                                                                forceDocumentNumber: item.numer.toString().replace(/\//g, '_')
                                                                            }).then((r) => {
                                                                            if (r.data.error) {
                                                                                return SnackBar(r.data.message, "error")
                                                                            } else {
                                                                                if (r.data.fullpath) {
                                                                                    window.open(r.data.fullpath, "_blank")
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                {item.numer}
                                                            </Table.Cell>
                                                            <Table.Cell
                                                                style={{
                                                                    cursor: "pointer"
                                                                }}
                                                                className={"hover-lb"}
                                                                onClick={() => copyToClipboard(item.wart_brt_pln)}
                                                            >
                                                                <Icon name={"copy"}/>
                                                                {Math.abs(item.wart_brt_pln)}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )
                                                })}
                                                </>
                                            </Table.Body>
                                        </Table>
                                    </div>
                                </Paper>

                                <Paper>
                                    <PaperTitle title={"Komentarze"}/>

                                    {data.comments ?
                                        <Comment.Group>
                                            {data.comments.map((comment, key) => {
                                                return (
                                                    <Comment key={key}>
                                                        <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/matt.jpg' />
                                                        <Comment.Content>
                                                            <Comment.Author as='a'>{comment.user}</Comment.Author>
                                                            <Comment.Metadata>
                                                                <div>{formatDate(comment.timestamp).dateTimeSystem}</div>
                                                            </Comment.Metadata>
                                                            <Comment.Text>{comment.value}</Comment.Text>
                                                            <Comment.Actions>
                                                                <Comment.Action
                                                                    onClick={() => this.removeComment(comment)}
                                                                >Usuń</Comment.Action>
                                                            </Comment.Actions>
                                                        </Comment.Content>
                                                    </Comment>
                                                )
                                            })}
                                        </Comment.Group>
                                        : <div style={{
                                            textAlign: "center",
                                            color: "#c6c6c6",
                                            margin: 20
                                        }}>Jeszcze nikt nie dodał żadnego komentarza</div>
                                    }


                                    <Form
                                        reply
                                        onSubmit={(value) => {
                                            this.addComment()
                                        }}
                                    >
                                        <Form.TextArea
                                            placeholder={"Treść..."}
                                            onChange={(e, v) => {
                                                this.state.newCommentValue = e.target.value;
                                            }}
                                        />
                                        <Button content='Dodaj komentarz' labelPosition='left' icon='edit' primary />
                                    </Form>
                                </Paper>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                        :
                        <div style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                        }}>
                            <div style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                background: "#fff",
                                opacity: 0.8
                            }}/>


                            <div style={{
                                width: "100%",
                                textAlign: "center",
                                position: "relative",
                                top: "40%",
                                fontSize: "1.6em",
                                color: "#2d2d2d",
                                fontWeight: 300
                            }}>
                                <div><Loader active></Loader></div>

                                <div style={{
                                    width: "100%",
                                    textAlign: "center",
                                    position: "relative",
                                    top: 50
                                }}>
                                    Pobieranie danych...
                                </div>
                            </div>
                        </div>
                    }

                    {this.state.changeStatus &&
                        <Modal dimmer={"inverted"} size={"mini"} open={this.state.changeStatus}
                               onClose={() => this.setState({changeStatus: false})}>
                            <Modal.Header>Zmień status</Modal.Header>
                            <Modal.Content>
                                {/*<EsotiqInput*/}
                                {/*    label={"Status"}*/}
                                {/*    options={[*/}
                                {/*        {status: "complaintNew", label: "Nowe"},*/}
                                {/*        {status: "complaintProgress", label: "W toku"},*/}
                                {/*        {status: "complaintAccepted", label: "Uznane"},*/}
                                {/*        {status: "complaintRejected", label: "Odrzucone"}*/}
                                {/*    ].map((e, k) => ({*/}
                                {/*        key: k, value: e, text: e.label*/}
                                {/*    }))}*/}
                                {/*    placeholder={"Wybierz status..."}*/}
                                {/*    onChange={(value) => this.setState({*/}
                                {/*        selectedStatus: value*/}
                                {/*    })}*/}
                                {/*/>*/}

                                {this.state.selectedStatus && (this.state.selectedStatus.status === "complaintRejected" || this.state.selectedStatus.status === "complaintAccepted") &&
                                    <EsotiqInput
                                        label={`(Opcjonalnie) Powód ${this.state.selectedStatus.status === "complaintRejected" ? "odrzucenia" : "akceptacji"} zgłoszenia`}
                                        placeholder={"Wpisz powód"}
                                        textArea={true}
                                        onChange={value => {
                                            this.setState({
                                                rejectedReason: value
                                            })
                                        }}
                                    />
                                }
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => this.setState({changeStatus: false})} negative>Zamknij</Button>
                                <Button onClick={() => this.changeStatus()} positive>Zapisz</Button>
                            </Modal.Actions>
                        </Modal>
                    }

                    {this.state.switchProducts &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.switchProducts}
                           onClose={() => this.setState({switchProducts: false})}>
                        <Modal.Header>Edytuj produkty w zgłoszeniu</Modal.Header>
                        <Modal.Content>
                            <div>
                                <Button
                                    primary={true}
                                    onClick={() => this.setState({
                                        productSwitchAddNew: this.state.productwitchAddNew ? null : true
                                    })}
                                >
                                    Dodaj nowy
                                </Button>

                                {this.state.productSwitchAddNew &&
                                    <EsotiqInput
                                        label={"Dostępne produkty"}
                                        options={this.state.data.orderData.items.map((e, k) => ({
                                            key: k, value: e, text: e.sku
                                        }))}
                                        placeholder={"Wybierz produkt..."}
                                        onChange={(value) => {
                                            this.setState({
                                                productSwitchAddNew: value
                                            })
                                            this.switchProduct(value, "new")
                                        }}
                                    />
                                }

                            </div>

                            <Table
                                basic='very'
                            >
                                <Table.Header>
                                    <Table.Row className={"ui-table-tr-background"}>
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell>SKU</Table.HeaderCell>
                                        <Table.HeaderCell>Produkt</Table.HeaderCell>
                                        <Table.HeaderCell>{this.state.switchProducts[0].revertType === "complaint" ? "Forma reklamacji" : "Powód zwrotu"}</Table.HeaderCell>
                                        <Table.HeaderCell>Ilość</Table.HeaderCell>
                                        <Table.HeaderCell>Wartość</Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {this.state.switchProducts.map((item, index) => {
                                        if (item.revertTok99) {
                                            data.revertToK99 = true
                                        }

                                        return (
                                            <Table.Row key={index}
                                                       className={"ui-table-tr-background"}>
                                                <Table.Cell width={3}>
                                                    <div style={{
                                                        cursor: "pointer"
                                                    }} onClick={() => {
                                                        if (!item.revertProduct.images) return false

                                                        this.setState({
                                                            imageModal: `${item.revertProduct.images[0].file}`
                                                        })
                                                    }}>
                                                        {item.revertProduct.images ? <img style={{
                                                            width: 60
                                                        }}
                                                                                          src={`${item.revertProduct.images[0].file}`}
                                                                                          alt=""/> : "---"}
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.sku}
                                                    {item.revertTok99 &&
                                                    <div style={{
                                                        fontSize: "0.85em",
                                                        color: "#ff4a2d",
                                                        fontWeight: 600
                                                    }}>Towar odeślij na mag. K99</div>
                                                    }
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.name}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {this.state.switchProducts[0].revertType === "complaint" ? item.complaintForm : item.revertReason}

                                                    <div style={{fontSize: "0.8em", color: "#939393"}}>Dodatkowe informacje:</div>
                                                    <div style={{fontSize: "0.8em", color: "#8d8d8d"}}>{item.revertAdditionalInfo}</div>
                                                </Table.Cell>
                                                <Table.Cell onClick={() => {
                                                    this.setState({
                                                        revertQtyChange: true
                                                    })
                                                }}>
                                                    {item.revertQty}

                                                    {this.state.revertQtyChange &&
                                                    <div>
                                                        <EsotiqInput
                                                            type={"number"}
                                                            onChange={value => {
                                                                if (value && value.length) {
                                                                    this.setState({
                                                                        revertQtyChangeValue: value
                                                                    })
                                                                }
                                                            }}
                                                        />

                                                        <div style={{
                                                            display: "flex",
                                                            marginTop: 5,
                                                            justifyContent: "flex-end"
                                                        }}>
                                                            <Button style={{
                                                                marginRight: 5
                                                            }} onClick={() => {
                                                                // api().post("/api/depotCollection/startCollection", {...row, user: this.state.newCollectionWorker})
                                                                //     .then(() => {
                                                                //         this.orderTable.setState({
                                                                //             data: null
                                                                //         })
                                                                //
                                                                //         this.orderTable.init()
                                                                //
                                                                //         SnackBar("Zaaktualizowano")
                                                                //     })

                                                                if (this.state.revertQtyChangeValue && this.state.revertQtyChangeValue.length) {
                                                                    item.revertQty = this.state.revertQtyChangeValue

                                                                    const dataProduct = this.state.data.revertData.find(e => e.sku === item.sku)

                                                                    dataProduct.revertQty = this.state.revertQtyChangeValue

                                                                    setTimeout(() => {
                                                                        this.setState({
                                                                            revertQtyChangeValue: null,
                                                                            revertQtyChange: false
                                                                        })
                                                                    }, 300)
                                                                }


                                                            }}>
                                                                Zapisz
                                                            </Button>

                                                            <Button negative={true} onClick={() => {
                                                                setTimeout(() => {
                                                                    this.setState({
                                                                        revertQtyChangeValue: null,
                                                                        revertQtyChange: false
                                                                    })
                                                                }, 300)
                                                            }}>
                                                                Anuluj
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    }
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {parseInt(item.revertProduct.price * item.revertQty).toFixed(2)}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <div style={{
                                                        marginBottom: 10
                                                    }}>
                                                        <Button
                                                            primary={true}
                                                            onClick={() => this.setState({
                                                                search: index
                                                            })}
                                                            loading={this.state.storeProducts ? false : true}
                                                            disabled={this.state.storeProducts ? false : true}
                                                        >
                                                            Wymień
                                                        </Button>

                                                        <Button
                                                            icon
                                                            onClick={() => {
                                                                this.switchProductDelete(index)
                                                            }}
                                                        >
                                                            <Icon name='trash'/>
                                                        </Button>
                                                    </div>

                                                    {this.state.search === index &&
                                                        <div className={"in-table-search"}>
                                                            <EsotiqInput
                                                                label={"Dostępne produkty"}
                                                                options={this.state.data.orderData.items.map((e, k) => ({
                                                                    key: k, value: e, text: e.sku
                                                                }))}
                                                                placeholder={"Wybierz produkt..."}
                                                                onChange={(value) => {
                                                                    this.setState({
                                                                        switchProductSelected: value
                                                                    })
                                                                    this.switchProduct(value, index)
                                                                }}
                                                            />

                                                            {this.state.switchProductSelected &&
                                                                <EsotiqInput
                                                                    label={"Zmień ilość"}
                                                                    defaultValue={this.state.switchProductSelected.qty}
                                                                    placeholder={"Wybierz produkt..."}
                                                                    onChange={(value) => {
                                                                    }}
                                                                />
                                                            }


                                                            {/*<Search*/}
                                                                {/*input={{ icon: 'search', iconPosition: 'left' }}*/}
                                                                {/*loading={isLoading}*/}
                                                                {/*onResultSelect={(e, v) => this.searchResultSelect(e, v)}*/}
                                                                {/*onSearchChange={(e, v) => this.searchChange(e, v)}*/}
                                                                {/*results={results}*/}
                                                                {/*value={value}*/}
                                                            {/*/>*/}

                                                            {/*{this.state.variantSelect &&*/}
                                                                {/*<EsotiqInput*/}
                                                                    {/*label={"Rozmiar"}*/}
                                                                    {/*options={this.state.variantSelect.variants.map((e, k) => ({*/}
                                                                        {/*key: k, value: e, text: e.sku*/}
                                                                    {/*}))}*/}
                                                                    {/*placeholder={"Wybierz rozmiar..."}*/}
                                                                    {/*onChange={(value) => this.setState({*/}
                                                                        {/*switchProductVariant: value,*/}
                                                                        {/*switchProductProduct: this.state.variantSelect.product*/}
                                                                    {/*})}*/}
                                                                {/*/>*/}
                                                            {/*}*/}

                                                            {/*{this.state.switchProductVariant &&*/}
                                                                {/*<div style={{*/}
                                                                    {/*marginTop: 10,*/}
                                                                    {/*display: "flex",*/}
                                                                    {/*justifyContent: "flex-end"*/}
                                                                {/*}}>*/}
                                                                    {/*<Button*/}
                                                                        {/*primary={true}*/}
                                                                        {/*onClick={() => this.approveSwitchProduct(item.sku)}*/}
                                                                    {/*>*/}
                                                                        {/*Potwierdź*/}
                                                                    {/*</Button>*/}
                                                                {/*</div>*/}
                                                            {/*}*/}

                                                        </div>
                                                    }
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({switchProducts: false})} negative>Zamknij</Button>
                            <Button onClick={() => this.saveSwitch()} positive>Zapisz</Button>
                        </Modal.Actions>
                    </Modal>
                    }

                    {this.state.imageModal &&
                        <Modal dimmer={"inverted"} size={"medium"} open={this.state.imageModal}
                               onClose={() => this.setState({imageModal: false})}>
                            <Modal.Header></Modal.Header>
                            <Modal.Content>
                                <div>
                                    <img style={{
                                        maxWidth: 500,
                                        margin: "0 auto",
                                        display: "block"
                                    }} src={this.state.imageModal} alt=""/>
                                </div>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => this.setState({imageModal: false})} negative>Zamknij</Button>
                            </Modal.Actions>
                        </Modal>
                    }
                </Container>
            </React.Fragment>
        );
    }
}
