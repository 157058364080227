import React from "react"
import {api} from "../../lib/axios";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import {Modal, Icon, Segment, Menu} from 'semantic-ui-react'
import MenuButton from "../../lib/table-v2/menuButton";
import EsotiqInput from "../../components/EsotiqInput";
import {TranslationController} from "../../controller/menager";
import Button from "../../components/Button";
import UITable from "../../lib/table-v2";
import Confirm from "../../components/Confirm";
import SnackBar from "../../components/Snack";
import {mailerTemplates, mailerVariants} from "../../lib/mailerTemplates";
import TableMenu from "../../components/TableMenu";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default class ShippingMethodsScene extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pickupInStore: false,
            isManagementPanel: false,
            paymentMethods: [],
            warehouses: [
                {name: "K99", id: "k99"},
                {name: "N32", id: "n32"},
                {name: "E99", id: "e99"},
                {name: "157", id: "157"},
                {name: "K99 oraz 157", id: "k99_157"},
                {name: "E99 oraz 157", id: "e99_157"},
                {name: "E99, 157, salon", id: "e99_157_parlour"},
                {name: "Salony", id: "parlour"},
                {name: "Salony DE", id: "de"},
                {name: "VOS oraz K99", id: "vos"},
                {name: "PRIB", id: "prib"},
                {name: "UKR", id: "ukr"},
            ]
        }
    }

    componentDidMount() {
        process.env.REACT_APP_ENV === "stg" && this.setState({
          warehouses: [...this.state.warehouses, {name: "IP1", id: "ip1"}],
        })
        this.getConfig();

        api().post("/api/system/payment-methods/get", {limit: 1000, currentPage: 1, filter: this.state.appliedFilters || null})
            .then(r => {
                this.setState({paymentMethods: r.data.data.map(e => ({name: e.name, id: e.externalId, price: e.price}))})

                return r.data.data
            })

        api().post("/api/system/shipping-methods/get", {limit: 1000, currentPage: 1, filter: this.state.appliedFilters || null})
            .then(r => {
                this.setState({shippingMethods: r.data.data.map(e => ({name: e.name, id: e._id, price: e.price, _id: e._id}))})

                return r.data.data
            })
    }

    init(limit, currentPage) {

        return api().post("/api/system/shipping-methods/get", {limit, currentPage, filter: this.state.appliedFilters || null})
            .then(r => {
                this.setState({data: r.data.data})

                return r.data.data
            })
    }

    getConfig() {
        return api().get("/api/states/config")
            .then(r => {
                if (!r.data.data) {
                    this.setState({configs: [
                            {
                                bufforVGL: 0,
                                bufforSalVGLon: 0,
                                bufforSalVGLoff: 0,
                                shippingtime: {}
                            }
                        ]})

                } else {
                    this.setState({configs: [r.data.data]});

                    return r.data.data
                }
            })
    }

    setConfig() {
        return api().post("/api/states/config/set", {config: this.state.configs})
            .then(r => {
                this.setState({editConfig: false})

                return SnackBar("Zapisano pomyślnie.")
            })
    }

    render() {
        return (
            <React.Fragment>
                <Container>
                    {!this.props.simple &&
                    <React.Fragment>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `${TranslationController.translate("Metody wysyłki")} ${this.state.dataCount ? `(${this.state.dataCount})` : ""}`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>
                    </React.Fragment>
                    }


                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>
                                <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                                <TableMenu.Divider />
                                <TableMenu.Header>Import</TableMenu.Header>
                                <TableMenu.Item onClick={() => {
                                    if (!this.state.configs) {
                                        return SnackBar("Trwa pobieranie danych, spróbuj ponownie za kilka sekund.", "info")
                                    }

                                    if (!this.state.shippingMethods) {
                                        return SnackBar("Trwa pobieranie danych, spróbuj ponownie za kilka sekund.", "info")
                                    }

                                    this.setState({
                                        isManagementPanel: true
                                    });
                                }}>Zarządzaj metodami wysyłki</TableMenu.Item>

                            </TableMenu.Menu>
                        </TableMenu>

                        <Menu.Menu position='right'>
                            <MenuButton
                                icon={"user"}
                                onClick={() => {
                                    this.setState({
                                        addMethod: true,
                                    })
                                }}
                                label={"Dodaj metodę"}
                            />
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            dataFetch={{
                                url: "/api/system/shipping-methods/get",
                                options: {limit: 25, currentPage: 1}
                            }}
                            ref={table => this.userTable = table}
                            provider={this}
                            columns={[
                                {
                                    width: 4,
                                    field: "name",
                                    label: "Metoda",
                                    filters: [
                                        {label: "Nazwa", condition: "like", type: "text"},
                                        {label: "Pozycja", condition: "like", type: "text", forceField: "position"},
                                        {label: "Dostawca", condition: "like", type: "text", forceField: "provider"},
                                        {label: "Id zewnętrzne", condition: "like", type: "text", forceField: "externalId"}
                                    ],
                                    render: row => {
                                        return (
                                            <div className={"shipping-method-present-wrapper"}>
                                                <img src={row.iconUrl} alt=""/>

                                                <div className={"smpw-label"}>
                                                    <span>{TranslationController.translate("Nazwa")}:</span> {row.name}
                                                </div>

                                                <div className={"smpw-label"}>
                                                    <span>{TranslationController.translate("Pozycja")}</span>: {row.position}
                                                </div>

                                                <div className={"smpw-label"}>
                                                    <span>{TranslationController.translate("Zewnętrzne ID")}</span>: {row.externalId}
                                                </div>

                                                <div className={"smpw-label"}>
                                                    <span>{TranslationController.translate("Dostawca")}</span>: {row.provider ? row.provider.label : "---"}
                                                </div>

                                                <div className={"smpw-label"}>
                                                    <span>{TranslationController.translate("Marketplace")}</span>: {row.marketplace || "---"}
                                                </div>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    field: "brand",
                                    label: "Brand",
                                    center: true,
                                    width: 1,
                                    filters: [
                                        {label: "Brand", condition: "like", type: "text"}
                                    ],
                                    render: row => {
                                        return (
                                            <div className={"shipping-method-brand-wrapper"}>
                                                <div className={"smbw-label"}>{row.brand} <span>({row.country})</span></div>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    field: "shippingAmount",
                                    label: "Cena wysyłki",
                                    center: true,
                                    width: 1,
                                    filters: [
                                        {label: "Cena wysyłki", condition: "like", type: "text"},
                                        {label: "Walute", condition: "like", type: "text", forceField: "currency"}
                                    ],
                                    render: row => {
                                        return (
                                            <div className={"shipping-method-brand-wrapper"}>
                                                <div className={"smbw-label"}>{row.shippingAmount} <span>({row.currency})</span></div>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    field: "freeDeliveryFrom",
                                    label: "Darmowa wysyłka od",
                                    center: true,
                                    width: 1,
                                    filters: [
                                        {label: "Darmowa wysyłka od", condition: "like", type: "text"},
                                        {label: "Walute", condition: "like", type: "text", forceField: "currency"}
                                    ],
                                    render: row => {
                                        return (
                                            <div className={"shipping-method-brand-wrapper"}>
                                                <div className={"smbw-label"}>{row.shippingAmount} <span>({row.currency})</span></div>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    field: "availablePaymentMethods",
                                    label: "Metody płatności",
                                    center: true,
                                    width: 1,
                                    // filters: [
                                    //     {label: "Metoda płatności", condition: "match", type: "text", forceField: "currency"}
                                    // ],
                                    render: row => {
                                        return (
                                            <div className={"shipping-method-payment-methods-wrapper"}>
                                                {(row.availablePaymentMethods || []).map((method, key) => {
                                                    return (
                                                        <div className={"smpmw-box"} key={key}>
                                                            <Icon name={"caret right"}/>

                                                            <div className={"smpmw-label"}>{method.name}</div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    }
                                },
                                {
                                    field: "availableWarehouses",
                                    label: "Dostępna w magazynie",
                                    center: true,
                                    width: 1,
                                    // filters: [
                                    //     {label: "Metoda płatności", condition: "match", type: "text", forceField: "currency"}
                                    // ],
                                    render: row => {
                                        return (
                                            <div className={"shipping-method-payment-methods-wrapper"}>
                                                {(row.availableWarehouses || []).map((warehouse, key) => {
                                                    return (
                                                        <div className={"smpmw-box"} key={key}>
                                                            <Icon name={"warehouse"}/>

                                                            <div className={"smpmw-label"}>{warehouse.name}</div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    }
                                },
                                {
                                    label: "Edytuj",
                                    field: "products",
                                    width: 1,
                                    render: row => {
                                        return (
                                            <div style={{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                this.setState({
                                                    addMethod: true,
                                                    methodId: row._id,
                                                    name: row.name,
                                                    externalId: row.externalId,
                                                    brand: row.brand,
                                                    country: row.country,
                                                    iconUrl: row.iconUrl,
                                                    position: row.position,
                                                    currency: row.currency,
                                                    shippingAmount: row.shippingAmount,
                                                    freeDeliveryFrom: row.freeDeliveryFrom,
                                                    availablePaymentMethods: row.availablePaymentMethods,
                                                    availableWarehouses: row.availableWarehouses,
                                                    provider: row.provider,
                                                    mailerTemplates: row.mailerTemplates,
                                                    marketplace: row.marketplace
                                                })
                                            }}>
                                                <i className='edit link icon' />
                                            </div>
                                        )
                                    },
                                    center: true
                                },
                                {
                                    label: "Usuń",
                                    field: "products",
                                    width: 1,
                                    render: row => {
                                        return (
                                            <div style={{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                Confirm("Czy na pewno chcesz trwale usunąć tą metodę wysyłki?", null, () => {
                                                    api().post("/api/system/shipping-methods/delete", {id: row._id})
                                                        .then(() => {
                                                            SnackBar("Usunięto")

                                                            this.userTable.setState({
                                                                data: null
                                                            })

                                                            this.userTable.init()
                                                        })
                                                })
                                            }}>
                                                <i className='trash link icon' />
                                            </div>
                                        )
                                    },
                                    center: true
                                },
                            ]}
                        />
                    </Segment>

                    {this.state.addMethod &&
                    <Modal dimmer={"inverted"} size={"mini"} open={this.state.addMethod}
                           onClose={() => this.setState({addMethod: false})}>
                        <Modal.Header>{this.state.methodId ? TranslationController.translate("Edytuj metodę wysyłki") : TranslationController.translate("Dodaj metodę wysyłki")}</Modal.Header>
                        <Modal.Content scrolling>
                            <div>
                                <EsotiqInput
                                    label={"Nazwa"}
                                    placeholder={"Nazwa metody wysyłki"}
                                    onChange={v => {
                                        let value = v;

                                        this.setState({
                                            name: value
                                        })
                                    }}
                                    defValue={this.state.name}
                                    width={300}
                                />

                                <EsotiqInput
                                    label={"Dostawca"}
                                    placeholder={"Wybierz przewoźnika z listy"}
                                    options={[
                                        {text: "DPD", key: "dpd", value: {id: "dpd", label: "DPD"}},
                                        {text: "DHL", key: "dhl", value: {id: "dhl", label: "DHL"}},
                                        {text: "Inpost", key: "inpost", value: {id: "inpost", label: "Inpost"}},
                                        {text: "Inpost Kurier", key: "inpost_courier", value: {id: "inpost_courier", label: "Inpost Kurier"}},
                                        {text: "Orlen", key: "orlen", value: {id: "orlen", label: "Orlen"}},
                                        {text: "GLS", key: "gls", value: {id: "gls", label: "GLS"}},
                                    ]}
                                    onChange={(value, e) => {
                                        this.setState({
                                            provider: value
                                        })
                                    }}
                                    defValue={this.state.provider ? this.state.provider.label : null}
                                    width={300}
                                />

                                <EsotiqInput
                                    label={"Zewnętrzne ID"}
                                    placeholder={"Wpisz ID z systemu AppChance"}
                                    onChange={v => {
                                        let value = v;

                                        this.setState({
                                            externalId: value
                                        })
                                    }}
                                    defValue={this.state.externalId}
                                    width={300}
                                />

                                <EsotiqInput
                                    label={"Brand"}
                                    placeholder={"Wprowadź nazwę marki"}
                                    onChange={v => {
                                        let value = v;

                                        value = value.toLowerCase();

                                        this.setState({
                                            brand: value
                                        })
                                    }}
                                    defValue={this.state.brand}
                                    width={300}
                                />

                                <EsotiqInput
                                    label={"Marketplace"}
                                    placeholder={"Wprowadź nazwę marketplace"}
                                    onChange={v => {
                                        let value = v;
                                        value = value.toLowerCase();

                                        this.setState({
                                            marketplace: value
                                        })
                                    }}
                                    defValue={this.state.marketplace}
                                    width={300}
                                />

                                <EsotiqInput
                                    label={"Kraj"}
                                    placeholder={"Wprwoadź kod kraju"}
                                    onChange={v => {
                                        let value = v;

                                        value = value.toLowerCase();

                                        this.setState({
                                            country: value
                                        })
                                    }}
                                    defValue={this.state.country}
                                    width={300}
                                />

                                <EsotiqInput
                                    label={"Ikona"}
                                    placeholder={"Wprowadź pełny URL do ikony"}
                                    onChange={value => {
                                        this.setState({
                                            iconUrl: value
                                        })
                                    }}
                                    defValue={this.state.iconUrl}
                                    width={300}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox checked={this.state.pickupInStore}  onChange={() =>
                                            this.setState({
                                                pickupInStore: !this.state.pickupInStore
                                            })} />
                                    }
                                    label="Odbiór w salonie"
                                />

                                <EsotiqInput
                                    label={"Pozycja"}
                                    placeholder={"Pozycja sortowania na liście"}
                                    onChange={value => {
                                        this.setState({
                                            position: value
                                        })
                                    }}
                                    defValue={this.state.position}
                                    width={300}
                                />

                                <EsotiqInput
                                    label={"Waluta"}
                                    placeholder={"Waluta"}
                                    onChange={value => {
                                        this.setState({
                                            currency: value
                                        })
                                    }}
                                    defValue={this.state.currency}
                                    width={300}
                                />

                                <EsotiqInput
                                    label={"Koszt wysyłki"}
                                    placeholder={"Koszt wysyłki"}
                                    type={"number"}
                                    onChange={value => {
                                        this.setState({
                                            shippingAmount: value
                                        })
                                    }}
                                    defValue={this.state.shippingAmount}
                                    width={300}
                                />

                                <EsotiqInput
                                    label={"Darmowa wysyłka od"}
                                    placeholder={"Darmowa wysyłka od"}
                                    type={"number"}
                                    onChange={value => {
                                        this.setState({
                                            freeDeliveryFrom: value
                                        })
                                    }}
                                    defValue={this.state.freeDeliveryFrom}
                                    width={300}
                                />

                                <EsotiqInput
                                    label={"Metody płatości"}
                                    placeholder={"Wybierz dostepne metody płatności"}
                                    options={this.state.paymentMethods.map((e, k) => ({
                                        key: e.id, value: e.id, text: e.name
                                    }))}
                                    multiple={true}
                                    onChange={value => {
                                        const paymentMethodsMap = {}

                                        this.state.paymentMethods.forEach(method => paymentMethodsMap[method.id] = method)

                                        this.setState({
                                            availablePaymentMethods: value.filter(e => paymentMethodsMap[e]).map(e => paymentMethodsMap[e] && ({
                                                name: paymentMethodsMap[e].name,
                                                id: paymentMethodsMap[e].id,
                                                price: paymentMethodsMap[e].price
                                            }))
                                        })
                                    }}
                                    defValue={(this.state.availablePaymentMethods || []).filter(e => e && e.id).map(e => e.id)}
                                    width={300}
                                />

                                <EsotiqInput
                                    label={"Magazyn"}
                                    placeholder={"Wybierz dostepność w magazynie"}
                                    options={this.state.warehouses.map((e, k) => ({
                                        key: e.id, value: e.id, text: e.name
                                    }))}
                                    multiple={true}
                                    onChange={value => {
                                        const warehouseMap = {}

                                        this.state.warehouses.forEach(warehouse => warehouseMap[warehouse.id] = warehouse)
                                        this.setState({
                                            availableWarehouses: value.map(e => {
                                               return {
                                                name: warehouseMap[e].name,
                                                id: warehouseMap[e].id
                                            }})
                                        })
                                    }}
                                    defValue={(this.state.availableWarehouses || []).filter(e => e.id).map(e => e.id)}
                                    width={300}
                                />

                                <div className={"ship-mailer-divider"}>
                                    <Icon name={"mail"}/>
                                    {TranslationController.translate("Mailer:")}
                                </div>

                                {
                                    mailerVariants
                                        .map((e, k) => {
                                            return (
                                                <EsotiqInput
                                                    key={k}
                                                    label={`${e.label}`}
                                                    placeholder={"Wybierz szablon"}
                                                    options={mailerTemplates.map((e, k) => ({
                                                        key: e.key, value: e.value, text: e.text
                                                    }))}
                                                    onChange={value => {
                                                        if (!this.state.mailerTemplates) {
                                                            this.state.mailerTemplates = {};
                                                        }

                                                        this.state.mailerTemplates[e.key] = value;
                                                    }}
                                                    defValue={(this.state.mailerTemplates || {})[e.key]}
                                                    width={300}
                                                />
                                            )
                                        })
                                }
                            </div>
                        </Modal.Content>

                        <Modal.Actions>
                            <Button onClick={() => {
                                if (!this.state.name) {
                                    return SnackBar("Nie podałeś nazwy", "error")
                                }

                                if (this.state.name.length < 4) {
                                    return SnackBar("Nazwa jest za krótka", "error")
                                }

                                if (!this.state.brand) {
                                    return SnackBar("Nie podałeś brand", "error")
                                }

                                api().post(this.state.methodId ? "/api/system/shipping-methods/update" : "/api/system/shipping-methods/create", {
                                    methodId: this.state.methodId,
                                    name: this.state.name,
                                    externalId: this.state.externalId,
                                    brand: this.state.brand,
                                    country: this.state.country,
                                    iconUrl: this.state.iconUrl,
                                    position: this.state.position,
                                    currency: this.state.currency,
                                    shippingAmount: parseFloat(this.state.shippingAmount),
                                    freeDeliveryFrom: this.state.freeDeliveryFrom,
                                    availablePaymentMethods: this.state.availablePaymentMethods,
                                    availableWarehouses: this.state.availableWarehouses,
                                    provider: this.state.provider,
                                    pickupInStore: this.state.pickupInStore,
                                    mailerTemplates: this.state.mailerTemplates,
                                    marketplace: (this.state.marketplace && this.state.marketplace.length > 1) ? this.state.marketplace : null
                                })
                                    .then((res) => {
                                        if (res.data.error) {
                                            return SnackBar(res.data.message, "error")
                                        }

                                        SnackBar(this.state.method ? "Zaktualizowano metodę wysyłki" : "Dodano nową metodę wysyłki")

                                        this.setState({
                                            methodId: null,
                                            addMethod: false,
                                            name: null,
                                            externalId: null,
                                            brand: null,
                                            country: null,
                                            iconUrl: null,
                                            position: null,
                                            currency: null,
                                            shippingAmount: null,
                                            freeDeliveryFrom: null,
                                            availablePaymentMethods: null,
                                            availableWarehouses: null,
                                            provider: null,
                                            mailerTemplates: null,
                                            marketplace: null
                                        });

                                        this.userTable.setState({
                                            data: null
                                        });

                                        this.userTable.init()
                                    })
                            }} positive>{TranslationController.translate("Zapisz i zamknij")}</Button>
                            <Button onClick={() => this.setState({addMethod: false})} negative>{TranslationController.translate("Zamknij")}</Button>
                        </Modal.Actions>
                    </Modal>
                    }

                    {this.state.isManagementPanel &&
                        <Modal dimmer={"inverted"} size={"mini"} open={this.state.isManagementPanel}
                               onClose={() => this.setState({isManagementPanel: false})}>
                            <Modal.Header>{TranslationController.translate("Zarządzaj metodami wysyłki")}</Modal.Header>
                            <Modal.Content scrolling>
                                <EsotiqInput
                                    label={"Stauts - Wysłano do klienta - zostanie ustawiony jeśli zamówienie będzie posiadać metodę wysyłki:"}
                                    options={this.state.shippingMethods.map((e, k) => ({
                                        key: e.id, value: e.id, text: e.name
                                    }))}
                                    multiple={true}
                                    onChange={value => {
                                        const methodsMap = {}
                                        this.state.shippingMethods.forEach(method => methodsMap[method.id] = method);
                                        this.state.configs[0].sendToCustomerMethodId = value.map(e => ({
                                            name: methodsMap[e].name,
                                            id: methodsMap[e].id
                                        }));
                                    }}
                                    defValue={(this.state.configs[0].sendToCustomerMethodId || []).map(e => e.id)}
                                    width={300}
                                />

                                <EsotiqInput
                                    label={"Stauts - Wysłano do salonu - zostanie ustawiony jeśli zamówienie będzie posiadać metodę wysyłki:"}
                                    options={this.state.shippingMethods.map((e, k) => ({
                                        key: e.id, value: e.id, text: e.name
                                    }))}
                                    multiple={true}
                                    onChange={value => {
                                        const methodsMap = {}
                                        this.state.shippingMethods.forEach(method => methodsMap[method.id] = method)
                                        this.state.configs[0].sendToParlourMethodId = value.map(e => ({
                                            name: methodsMap[e].name,
                                            id: methodsMap[e].id
                                        }));
                                    }}
                                    defValue={(this.state.configs[0].sendToParlourMethodId || []).map(e => e.id)}
                                    width={300}
                                />

                                <div style={{height: 400}}/>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => {
                                    this.setConfig();
                                    this.setState({
                                        isManagementPanel: false
                                    });
                                }} positive>{TranslationController.translate("Zapisz i zamknij")}</Button>
                                <Button onClick={() => this.setState({isManagementPanel: false})} negative>{TranslationController.translate("Zamknij")}</Button>
                            </Modal.Actions>
                        </Modal>
                    }
                </Container>
            </React.Fragment>
        );
    }
}
