import React from "react"
import Button from "../../components/Button";
import {Form, Loader, Message} from 'semantic-ui-react'
import EsotiqInput from "../../components/EsotiqInput";
import SnackBar from "../../components/Snack";
import {api} from "../../lib/axios";
import {DATA_OBJECT} from "../../controller/dataController";

export default class OnlineComplaintFindOrder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            customerEmail: null,
            customerPhone: 513737725,
            orderId: 1099020118075331,
            loading: false
        }
    }

    search() {
        if ((this.props.provider.IS_WAREHOUSE_MODE || (this.state.customerEmail || this.state.customerPhone)) && this.state.orderId) {
            this.setState({
                loading: true
            })

            api().post("/api/orders/getByCustomerData", {
                orderId: (this.state.orderId && this.state.orderId.length) ? parseInt(this.state.orderId) : null,
                phone: (this.state.customerPhone && this.state.customerPhone.length) ? this.state.customerPhone : null,
                email: (this.state.customerEmail && this.state.customerEmail.length) ? this.state.customerEmail : null,
                isWarehouse: this.props.provider.IS_WAREHOUSE_MODE
            })
                .then(r => {
                    if(r.data.data.some(e => e.status === "canceled")) {
                        this.setState({
                            loading: false
                        })
                        return SnackBar("Zamówienie zostało anulowane.", "error")
                    }

                    if(r.data.data.some(order => order.isOnlyGiftCardOrder)) {
                        this.setState({
                            loading: false
                        })
                        return SnackBar("Zamówienie zawiera tylko karty podarunkowe, nie można dokonać zwrotu", "error")
                    }

                    let goNext = null;

                    if (r.data.data.length) {
                        return api().post("/api/complaints/get", {orderId: r.data.data[0].id})
                            .then((resp) => {

                                goNext = () => {
                                    this.props.provider.checkPreviousCompalintsProducts(resp.data.data)
                                    this.props.setData(r.data.data)
                                    this.props.provider.nextStep()
                                }
                            })
                            .then(() => api().post("/api/products/getEan", {sku: r.data.data[0].items.map(e => e.sku)}))
                            .then(r2 => {
                                let eans = r2.data.data;
                                let block = false;

                                r.data.data[0].items.filter(item => !item.isGiftCard).map(item => {
                                    const itemEan = eans.find(ean => ean.sku === item.sku)

                                    if (itemEan) {
                                        item.ean = itemEan.ean
                                    } else {
                                        SnackBar("Jeden z towarów nie posiada EAN. Zgłośc problem do operatora.", "error")

                                        block = true;
                                    }
                                })

                                if (!block) {
                                    this.setState({
                                        loading: false,
                                        orders: r.data.data
                                    })

                                    goNext();
                                }
                            })
                    } else {
                        this.setState({
                            loading: false,
                            orders: r.data.data
                        })
                    }
                })
        } else {
            return SnackBar("Musisz podać co najmniej dwa kryteria wyszukiwania.", "error")
        }
    }

    render() {
        return (
            <div>
                {/*<div>21595321225</div>*/}
                {this.state.orders &&
                    <Message
                        positive={this.state.orders.length ? true : false}
                        error={this.state.orders.length ? false : true}
                        icon='inbox'
                        content={
                            this.state.orders.length ?
                                this.state.orders.length > 1 ?
                            "Znaleziono " + this.state.orders.length + " zamówień spełniające podane kryteria."
                                    : "Znaleziono jedno zamówienie spełniające podane kryteria."
                            : "Nie znaleziono zamówenia spełniającego podane kryteria."
                        }
                    />
                }


                <Form onSubmit={() => this.search()} style={{width: 600}}>
                    {this.state.loading &&
                        <div style={{
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            position: "absolute",
                            background: "#fff",
                            zIndex: 99
                        }}>
                            <Loader
                                active={true}
                            >Proszę czekać, trwa wyszukiwanie zamówienia...</Loader>
                        </div>
                    }

                    <EsotiqInput
                        onChange={value => this.setState({
                            orderId: value
                        })}
                        type={"number"}
                        label={"Numer zamówienia"}
                        width={600}
                        placeholder={"Wpisz numer"}
                    />

                    {!this.props.provider.IS_WAREHOUSE_MODE &&
                        <React.Fragment>
                            <EsotiqInput
                                onChange={value => this.setState({
                                    customerPhone: value
                                })}
                                label={"Numer telefonu"}
                                width={600}
                                placeholder={"Wpisz numer"}
                                type={"number"}
                            />

                            <div style={{
                                margin: "20px 0 10px",
                                fontSize: "0.9em",
                                fontWeight: 600,
                                textTransform: "uppercase",
                                textAlign: "center",
                                color: "#b2b2b2"
                            }}>
                                - lub -
                            </div>

                            <EsotiqInput
                                onChange={value => this.setState({
                                    customerEmail: value
                                })}
                                label={"Adres email"}
                                width={600}
                                placeholder={"Wpisz email"}
                                type={"email"}
                            />
                        </React.Fragment>
                    }

                    <Button
                        style={{
                            marginTop: 10
                        }}
                        primary
                    >Wyszukaj</Button>
                </Form>
            </div>
        );
    }
}
