import React from "react"
import {DATA_OBJECT} from "../../controller/dataController";
import Breadcrumb from "../../components/Breadcrumb";
import {api, controlPanelApi, STATIC_URL} from "../../lib/axios";
import Container from "../../components/Container";
import UITable from "../../lib/table-v2";
import Icon from '@material-ui/core/Icon';
import {Download, saveToFile} from "../../controller/exportController";
import {OrderStatusesHelper} from "../../helpers/orderStatuses";
import {Icon as IconSemantic, Menu, Segment, Checkbox, Dimmer, Loader, Modal, Comment, Form} from 'semantic-ui-react'
import SnackBar from "../../components/Snack";
import Confirm from "../../components/Confirm";
import MenuButton from "../../lib/table-v2/menuButton";
import {daysDiff, formatDate} from "../../helpers/dateHelper";
import {TranslationController} from "../../controller/menager";
import Paper from '@material-ui/core/Paper';
import PaperTitle from "../../components/PaperTitle";
import DisplayCart from "./orderPreview/cartDisplay";
import Button from "../../components/Button";
import AdditionalFilters from "./AdditionalFilters";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";
import OrderPreview from "./orderPreview";
import EsotiqInput from "../../components/EsotiqInput";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TableMenu, {closeTableMenu, preventCloseTableMenu} from "../../components/TableMenu";
import pl from 'date-fns/locale/en-GB';
import ModalComponent from '../../components/Modal';
import WszystkoPl from "../../resources/wszystkopl-logo.png";
import AllegroLogo from "../../resources/allegrologo.png";
import ModivoLogo from "../../resources/modivo-logo.png";
import EmpikLogo from "../../resources/empik.png";
import EmagLogo from "../../resources/emag.svg";
import Dropdown from "../../components/Dropdown";
import InMemoryScanState from "../../helpers/inMemoryScanState";


function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: "10px 0" }}>
            {props.children}
        </Typography>
    );
}

class Orders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            tab: 0,
            tabs: [],
            tabsInitialized: false,
            websiteFilter: [],
            dataCount: null,
            dataProtocolCount: null,
            tableSelectable: false,
            tableSelectableState: [],
            tableExportCols: [],
            internalOrders: (localStorage.getItem("is_internal") === "true") || false,
            shippingMethods: {},
            shippingMethodsList: [],
            generateProtocolDataSelected: [],
            paymentMethods: {},
            paymentMethodsList: [],
            loadingCount: 0,
            addFiltersMaxWidth: 0,
            selectedRows: [],
            orderDateRangeMonths: 4
        };

        this.exportFields = [
            {
                field: "id",
                label: "Numer zamówienia",
                toStr: true
            },
            {
                field: "country",
                label: "Kraj"
            },
            {
                field: "brand",
                label: "Brand"
            },
            {
                field: "paymentMethod",
                label: "Metoda płatności"
            },
            {
                field: "shippingMethod",
                label: "Metoda wysyłki"
            },
            {
                field: "sendFrom.parlour",
                label: "Wysyłka z"
            },
            {
                field: "sendTo.parlour",
                label: "Wysyłka do"
            },
            {
                field: "shippingPoint.name",
                label: "Punkt odbioru"
            },
            {
                field: "addressShipping.firstname",
                label: "Adres wysyłki (nazwa)"
            },
            {
                field: "addressShipping.postcode",
                label: "Adres wysyłki (kod pocztowy)"
            },
            {
                field: "addressShipping.city",
                label: "Adres wysyłki (miasto)"
            },
            {
                field: "addressShipping.street",
                label: "Adres wysyłki (ulica)"
            },
            {
                field: "addressShipping.streetNumber",
                label: "Adres wysyłki (Nr ulicy)"
            },
            {
                field: "addressShipping.localNumber",
                label: "Adres wysyłki (Nr lokalu)"
            },
            {
                field: "addressShipping.telephone",
                label: "Adres wysyłki (Nr tel)"
            },
            {
                field: "addressBilling.company",
                label: "Adres rozliczeń (Firma)"
            },
            {
                field: "addressBilling.vat_id",
                label: "Adres rozliczeń (NIP)"
            },
            {
                field: "addressBilling.telephone",
                label: "Adres rozliczeń (Nr tel)"
            },
            {
                field: "shippingToTime",
                label: "Data planowanej wysyłki",
                backend_format: "DATE",
            },
            {
                field: "status",
                label: "Status"
            },
            {
                field: "createdAt",
                label: "Stworzono",
                backend_format: "DATE",
            },
            {
                field: "price",
                label: "Wartość zam."
            },
            {
                field: "statusPayment",
                label: "Status płatności"
            },
            {
                field: "paymentProvider",
                label: "Dostawca płatności"
            },
            {
                field: "customerEmail",
                label: "Email klienta"
            },
            {
                field: "addressBilling.firstname",
                label: "Nazwa adr. rozl."
            },
            {
                field: "addressBilling.street",
                label: "Ulica adr. rozl."
            },
            {
                field: "addressBilling.streetNumber",
                label: "Ulica nr adr. rozl."
            },
            {
                field: "addressBilling.localNumber",
                label: "Ulica lok. adr. rozl."
            },
            {
                field: "addressBilling.city",
                label: "Miasto adr. rozl."
            },
            {
                field: "addressBilling.postcode",
                label: "Kod poczt. adr. rozl."
            },
            {
                field: "promotionCode",
                label: "Kod rabatowy"
            },
            {
                field: "promotionData.state.name",
                label: "Promocja"
            },
            {
                field: "cardNumber",
                label: "Numer karty",
                toStr: true
            },
            {
                field: "paymentData.external_id",
                label: "ID płatności"
            },
            {
                field: "allegroData.formData.payment.id",
                label: "ID płatności Allegro"
            },
            {
                field: "allegroFormId",
                label: "Allegro ID"
            },
            {
                field: "allegroData.marketplace",
                label: "Marketplace"
            },
            {
                field: 'addressShipping.email',
                label: 'E-mail (adres wysyłki)'
            },
            {field: 'shippingAmount', label: 'Koszt przesyłki'},
            {field: 'allegroData.baselinkerOrderId', label: 'Numer zamówienia Baselinker'},
            {
                field: "statusPaymentChanger",
                label: "Zmienił płatność"
            },
            {
               field: 'source', label: 'Źródło'
            },
            {
                field: "claimedByCustomer",
                label: "Data odebrania w salonie",
                backend_format: "DATE",
            },
            {
                field: "delivery.createdTime",
                label: "Data nadania paczki",
                backend_format: "DATE",
                alternative: "orderService.pickupCreateDate",
            },
            {
                field: "delivery.pickupNumber",
                label: "Numer paczki",
                alternative: "orderService.pickupNumber",
            },
            {
                field: "delivery",
                label: "Ilość listów przewozowych",
                alternative: "orderService",
                backend_format: "COUNT",
            },
            {
                field: "delivery.user",
                label: "Login osoby wystawiającej etykietę",
            },
            {
                field: "p_sku",
                label: "Produkt SKU",
            },
            {
                field: "p_name",
                label: "Produkt nazwa",
            },
            {
                field: "p_qty",
                label: "Produkt ilość",
            },
            {
                field: "p_price",
                label: "Produkt cena",
            },
            {
                field: "p_finalQtyPrice",
                label: "Produkt cena suma",
            },
            {
                field: "p_base_price",
                label: "Produkt cena bazowa",
            },
            {
                field: "p_gateStatusACount",
                label: "Ilość status GATE A"
            },
            {
                field: "p_gateStatusBCount",
                label: "Ilość status GATE B"
            },
            {
                field: "isClub",
                label: "Esotiq Club",
            },
            {
                field: "exportComments",
                label: "Komentarze",
            },
            {
                field: "numberMM",
                label: "Numer MM",
            },
            {
                field: "invoiceNo",
                label: "Numer faktury"
            },
            {
                field: "invoiceDetails.createdAt",
                label: "Data wystawienia FV",
                backend_format: "DATE",
            },
            {
                field: "packListNumber",
                label: "Numer listy pakowej"
            },
            {
                field: "packListWorker",
                label: "Osoba zgłaszająca"
            }
        ]
    }

    componentDidMount() {
        let cachedOrderIds = localStorage.getItem("cached_list_order_ids");

        if (cachedOrderIds) {
            cachedOrderIds = JSON.parse(cachedOrderIds);
        } else {
            cachedOrderIds = [];
        }

        if (cachedOrderIds.length) {
            this.state.tabs = cachedOrderIds.map(e => ({id: e}));

            api().post("/api/orders", {
                limit: 25,
                currentPage: 1,
                filter: [{
                    condition: "in",
                    field: "id",
                    value: cachedOrderIds.map(e => e)
                }],
                internalOrders: false,
                user: DATA_OBJECT["user"]
            })
                .then(r => {
                    this.state.tabs = [];

                    r.data.data.map(orderData => this.state.tabs.push(orderData));

                    this.state.tabsInitialized = true;
                    this.forceUpdate();
                })
        } else {
            this.state.tabsInitialized = true;
        }

        // api().post("/api/orders/externalCheck", {})
        //     .then(r => {
        //         this.setState({
        //             externalCheck: r.data.result,
        //         })
        //     });

        api().post("/api/system/shipping-methods/get", {limit: 99999, currentPage: 1})
            .then(r => {
                const dataMap = {};

                r.data.data.map(method => {
                    dataMap[method._id] = method;
                    dataMap[method.externalId] = method;
                });

                this.setState({
                    shippingMethods: dataMap,
                    shippingMethodsList: r.data.data,
                    loadingCount: this.state.loadingCount + 1
                })
            });

        api().post("/api/system/payment-methods/get", {limit: 99999, currentPage: 1})
            .then(r => {
                const dataMap = {};

                r.data.data.map(method => {
                    dataMap[method._id] = method;
                    dataMap[method.externalId] = method;
                });

                this.setState({
                    paymentMethods: dataMap,
                    paymentMethodsList: r.data.data.sort((a, b) => a.position - b.position),
                    loadingCount: this.state.loadingCount + 1
                })
            });

        const initFilters = [];

        const applyInitfilter = () => {
            if (this.orderTable) {
                this.orderTable.applyFilter(initFilters)
            } else {
                return setTimeout(() => applyInitfilter(), 500)
            }
        };

        if (this.props.location && this.props.location.state && this.props.location.state.orderIds) {
            initFilters.push({
                condition: "in",
                deepFilter: undefined,
                field: "id",
                label: "ID",
                pattern: undefined,
                patternFunc: undefined,
                value: this.props.location.state.orderIds
            })
        }

        if (initFilters.length) {
            applyInitfilter();
        }
    }

    init(limit, currentPage) {
        this.setState({
            noLimitCall: api().post("/api/orders")
                .then(r => {
                    return r.data.data
                })
        })

        return api().post("/api/orders", {
            limit,
            currentPage,
            filter: this.state.appliedFilters || null,
            internalOrders: this.state.internalOrders,
            user: DATA_OBJECT["user"]
        })
            .then(r => {
                this.setState({data: r.data.data})

                return r.data.data
            })
    }

    addComment() {
        if (!this.state.newCommentValue) {
            return SnackBar("Wprowadzona wartość nie może być pusta.", "error")
        }

        if (this.state.newCommentValue.length < 4) {
            return SnackBar("Wprowadzony tekst jest za krótki.", "error")
        }

        api().post("/api/orders/comments/add", {
            value: this.state.newCommentValue,
            timestamp: new Date().getTime(),
            user: DATA_OBJECT.user_login,
            orderId: this.state.orderData.id
        })
            .then(() => {
                this.setState({
                    newCommentValue: "",
                })

                SnackBar("Pomyślnie zaktualizowano.")
            })
    }

    removeComment(comment) {
        if (DATA_OBJECT.user_login !== comment.user) {
            return SnackBar("Możesz usuwać tylko własne komenatrze", "error")
        }

        api().post("/api/orders/comments/remove", {...comment})
            .then(() => {
                this.setState({
                    refresh: new Date().getTime(),
                });

                SnackBar("Pomyślnie zaktualizowano.")
            })
    }

    uniExport() {
        this.setState({
            loading: true
        });

        preventCloseTableMenu(false);
        closeTableMenu();

        return api(true).post("/api/orders", {
            isExport: {
                columns: this.state.tableExportCols,
                fields: this.exportFields
            },
            limit: 35000,
            currentPage: 1,
            filter: this.orderTable.state.appliedFilters,
            internalOrders: this.state.internalOrders
        })
            .then(r => {
                this.setState({
                    loading: false,
                    tableExportCols: []
                });

                if (r.data.error) {
                    return SnackBar(r.data.message, "error")
                }

                return saveToFile(r.data);
            });
    }

    getProtocolData() {
        api().post("/api/orders/getDeliveryProtocol").then((r) => {
            this.setState({
                generateProtocolData: r.data || []
            })
        })
    }

    baselinkerProtocolApi( marketplace, brand, packagesNumbers ) {
        this.setState({
            loading: true
        });
        controlPanelApi.get(`/baselinker/get-protocol/${marketplace}/${brand}?packagesNumbers=${packagesNumbers.join(',')}`).then((r) => {
            this.state.generateProtocolDataSelected = [];

            const arrayBuffer = Uint8Array.from(r.data.protocol.data).buffer;

            const blob = new Blob([arrayBuffer], {type: 'application/pdf'});
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, "_blank");

            this.setState({
                refresh: new Date().getTime()
            })
        }).catch((e) => {
            if(e.response) SnackBar(e.response.data, "error");
            else SnackBar(e.message, "error");
        }).finally(() => {
            this.setState({
                loading: false
            })
            SnackBar("Pomyślnie wygenerowano protokół");
        });
    }

    generateBaselinkerProtocol() {
        const data = this.protocolTable.getSelectedState();

        if (!data.length) {
            return SnackBar("Nie wybrano żadnej pozycji", "error")
        }

        if(data.some(e => e.delivery.type !== "baselinker")) {
            return SnackBar("Nie można wygenerować protokołu dla zamówień z innych dostawców niż Baselinker", "error")
        }
        const allegroEsotiqProtocols = [];
        const allegroHendersonProtocols = [];
        const allegroFinalsaleProtocols = [];

        data.forEach(e => {
            if(e.allegroData.marketplace === "allegro" && e.brand === "esotiq") {
                allegroEsotiqProtocols.push({
                    marketplace: e.allegroData.marketplace,
                    packageNumber: e.delivery.data.packageNumber,
                    brand: 0,
                })
            }
            if(e.allegroData.marketplace === "allegro" && e.brand === "henderson") {
                allegroHendersonProtocols.push({
                    marketplace: e.allegroData.marketplace,
                    packageNumber: e.delivery.data.packageNumber,
                    brand: 1,
                })
            }
            if(e.allegroData.marketplace === "allegro" && e.brand === "finalsale") {
                allegroFinalsaleProtocols.push({
                    marketplace: e.allegroData.marketplace,
                    packageNumber: e.delivery.data.packageNumber,
                    brand: 2,
                })
            }
        })

        if (allegroEsotiqProtocols.length) {
            this.baselinkerProtocolApi(allegroEsotiqProtocols[0].marketplace, allegroEsotiqProtocols[0].brand, allegroEsotiqProtocols.map(e => e.packageNumber));
        }

        if (allegroHendersonProtocols.length) {
            this.baselinkerProtocolApi(allegroHendersonProtocols[0].marketplace, allegroHendersonProtocols[0].brand, allegroHendersonProtocols.map(e => e.packageNumber));
        }

        if (allegroFinalsaleProtocols.length) {
            this.baselinkerProtocolApi(allegroFinalsaleProtocols[0].marketplace, allegroFinalsaleProtocols[0].brand, allegroFinalsaleProtocols.map(e => e.packageNumber));
        }
    }

    generateProtocol(isFinalsale) {
        const data = this.protocolTable.getSelectedState();

        if (!data.length) {
            return SnackBar("Nie wybrano żadnej pozycji", "error")
        }

        if(data.some(e => e.delivery.type === "baselinker")) {
            return SnackBar("Nie można wygenerować protokołu dla zamówień z Baselinkera", "error")
        }

        if(data.some(e => e.delivery.protocol)) {
            return SnackBar("Nie można wygenerować protokołu dla zamówień, które już mają protokół", "error")
        }

        const dpdProtocols = [];
        const ruchProtocols = [];
        const glsProtocols = [];

        data.forEach(e => {
            if (e.delivery && e.delivery.type === "dpd") {
                dpdProtocols.push(e)
            }

            if (e.delivery && e.delivery.type === "ruch") {
                ruchProtocols.push(e)
            }

            if (e.delivery && e.delivery.type === "gls") {
                glsProtocols.push(e)
            }
        });

        if (dpdProtocols.length) {
            api().post("/api/orderService/generateProtocol", {
                multi: true,
                data: dpdProtocols,
            }).then((r) => {
                this.state.generateProtocolDataSelected = [];

                if (r.data.file) {
                    SnackBar("Wygenerowano protokół, za chwile zostaniesz przekierowany.");

                    setTimeout(() => {
                        window.open(`${STATIC_URL}${r.data.file}`)
                    }, 2000)
                }

                if (r.data.error) {
                    SnackBar(r.data.message, "error")
                }

                this.setState({
                    refresh: new Date().getTime()
                })
            })
        }

        if (ruchProtocols.length) {
            api().post("/api/orderService/generateRuchProtocol", {
                multi: true,
                data: ruchProtocols,
                isFinalsale: isFinalsale
            }).then((r) => {
                this.state.generateProtocolDataSelected = [];

                if (r.data.file) {
                    SnackBar("Wygenerowano protokół, za chwile zostaniesz przekierowany.");

                    setTimeout(() => {
                        window.open(`${STATIC_URL}${r.data.file}`)
                    }, 1500)
                }

                if (r.data.error) {
                    SnackBar(r.data.message, "error")
                }

                this.setState({
                    refresh: new Date().getTime()
                })
            })
        }

        if(glsProtocols.length) {
            api().post("/api/orders/delivery/generateGlsProtocol", {
                orders: glsProtocols,
                user: DATA_OBJECT.user_login,
                parlour: DATA_OBJECT.selected_parlour
            }).then((r) => {
                this.state.generateProtocolDataSelected = [];

                if (r.data.protocol) {
                    SnackBar("Wygenerowano protokół, za chwile zostaniesz przekierowany.");

                    setTimeout(() => {
                        const arrayBuffer = Uint8Array.from(r.data.protocol.data).buffer;

                        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                        const blobUrl = URL.createObjectURL(blob);
                        window.open(blobUrl, "_blank")
                    }, 1000)
                }

                if (r.data.error) {
                    SnackBar(r.data.message, "error")
                }

                this.setState({
                    refresh: new Date().getTime()
                })
            }).catch((e) => {
                SnackBar('Nie udało się wygenerować protokołu.', "error")
            })
        }
    }

    handleChangeTab(event, newValue) {
        if (this.state.tab === newValue) {
            return;
        }

        if (newValue > 0 && !this.state.tabsInitialized) {
            SnackBar("Jeszcze chwila...", "info")

            return;
        }

        this.setState({
            tab: 0
        }, () => {
            this.setState({
                tab: newValue
            })
        })
    }

    handleAddTab(orderData) {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        let forceQuit = false;

        this.state.tabs.forEach((tabData, key) => {
            if (tabData.id === orderData.id) {
                this.state.tab = key + 1;
                this.forceUpdate();

                forceQuit = true;

                return;
            }
        })

        if (forceQuit) return;

        let cachedOrderIds = localStorage.getItem("cached_list_order_ids");

        if (cachedOrderIds) {
            cachedOrderIds = JSON.parse(cachedOrderIds);
        } else {
            cachedOrderIds = [];
        }

        cachedOrderIds.push(orderData.id);

        localStorage.setItem("cached_list_order_ids", JSON.stringify(cachedOrderIds));

        this.state.tabs.push(orderData);
        this.state.tab = this.state.tabs.length;
        this.forceUpdate();
    }

    handleCloseTab(index) {
        InMemoryScanState.getInstance().deleteByPartialKey(this.state.tabs[index].id);

        let cachedOrderIds = localStorage.getItem("cached_list_order_ids");

        if (cachedOrderIds) {
            cachedOrderIds = JSON.parse(cachedOrderIds);
        } else {
            cachedOrderIds = [];
        }

        cachedOrderIds = cachedOrderIds.filter(e => e != this.state.tabs[index].id);

        localStorage.setItem("cached_list_order_ids", JSON.stringify(cachedOrderIds));

        this.state.tabs.splice(index, 1);

        if (this.state.tab - 1 === index) {
            this.state.tab = this.state.tab - 1;
        }

        this.forceUpdate();
    }

    resetSelecting() {
        this.setState({
            tableSelectable: !this.state.tableSelectable,
            tableSelectableState: [],
            selectedRows: []
        })
    }

    transferOrderTo(warehouse) {
        if (this.state.selectedRows.length === 0) {
            SnackBar('Brak wybranych zamówień do przeniesienia', 'error')
            this.resetSelecting()
            return
        }
        const transferedOrders = this.state.selectedRows.filter((order) => order.transferedTo)
        if (transferedOrders.length > 0) {
            SnackBar(`${transferedOrders.map(order => order.id).toString()} było już przenoszone`, 'error')
            this.resetSelecting()
            return
        }
        return Confirm("Czy na pewno chcesz przenieść zamówienia?", null, () => {
            const promises = this.state.selectedRows.map(order => {
                return api().post("/api/orders/transfer", { order, userId: DATA_OBJECT.user_login, warehouse })
                    .then(response => {
                        if (response.data.error) {
                            return Promise.reject(`Wystąpił błąd podczas przenoszenia zamówienia ${order.id}: ${response.data.message}`);
                        }
                        return response;
                    });
            });

            Promise.all(promises)
                .then(() => {
                    SnackBar("Przeniesienie zamówień przebiegło pomyślnie");
                    this.resetSelecting();
                })
                .catch(error => {
                    console.error("Error during transfer:", error);
                    SnackBar(error, "error");
                    this.resetSelecting();
                });
        });
    }

    render() {
        return (
            <React.Fragment>
                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: `Zamówienia ${this.state.dataCount ? `(${this.state.dataCount})` : ""}`
                            }
                        ]}
                    />

                    {(((DATA_OBJECT["user"] && DATA_OBJECT["user"].login === "root")) && this.state.externalCheck) &&
                        <div>
                            <div className={"external-check-wrapper"}>
                                <div className={"external-check-row"}>
                                    <span className={"external-check-label"}>{TranslationController.translate("Zamówienia dzisiaj baza wewnętrzna:")}</span>
                                    <span className={"external-check-value"}>{this.state.externalCheck.todayOwnOrders}</span>
                                </div>

                                <div className={"external-check-row"}>
                                    <span className={"external-check-label"}>{TranslationController.translate("Zamówienia dzisiaj baza zewnętrzna:")}</span>
                                    <span className={"external-check-value"}>{this.state.externalCheck.todayExternalOrders}</span>
                                </div>

                                <div className={"external-check-row"}>
                                    <span className={"external-check-label"}>{TranslationController.translate("Brakujące zamówienia:")}</span>
                                    <span className={"external-check-value color-red"}>{this.state.externalCheck.missingOrders}</span>
                                </div>

                                {(this.state.externalCheck.missingOrders > 0) &&
                                    <div className={"external-check-row"}>
                                        <span className={"external-check-label"}>{TranslationController.translate("Brakujące ID:")}</span>
                                        <span className={"external-check-value"}>{this.state.externalCheck.missingOrdersIds.map((e, k, s) => `${e}${k < (s.length - 1) ? ", " : ""}`)}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    <div className={"orders-additional-filters"} style={{
                        display: "flex"
                    }}>
                        <div>
                            <div>

                            </div>

                            <div style={{
                                marginRight: 10
                            }}>
                                <div style={{
                                    display: "flex"
                                }}>
                                    <div style={{
                                        fontSize: "16px",
                                        color: "#3a7cff",
                                        cursor: "pointer"
                                    }} onClick={() => {
                                        Confirm("Pole \"Zakres czasowy zamówień (w miesiącach)\" pozwala filtrować zamówienia według określonego okresu. Wprowadź liczbę miesięcy, aby wyświetlić zamówienia z wybranego zakresu, domyślnie, ostatnich 4 miesięcy.", "", () => {})
                                    }}>
                                        <IconSemantic name={"question circle outline"}/>
                                    </div>
                                    <div style={{
                                        width: 250
                                    }}>
                                        <div style={{
                                            top: -30,
                                            fontSize: "0.9em"
                                        }} className={"drawer-dropdown-label"}>
                                            {TranslationController.translate("Zakres czasowy zamówień (w miesiącach)")}:
                                        </div>

                                        <Dropdown
                                            placeholder={"Select..."}
                                            fluid
                                            search
                                            selection
                                            defaultValue={this.state.orderDateRangeMonths}
                                            className={"drawer-dropdown"}
                                            onChange={(e, v) => {
                                                this.setState({
                                                    orderDateRangeMonths: parseInt(v.value)
                                                }, () => {
                                                    this.orderTable.setState({
                                                        data: null
                                                    });

                                                    this.orderTable.init()
                                                })
                                            }}
                                            options={[
                                                {key: 4, value: 4, text: 4},
                                                {key: 8, value: 8, text: 8},
                                                {key: 12, value: 12, text: 12},
                                                {key: 0, value: 0, text: "Wszystkie"}
                                            ]}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <Button
                            onClick={() => {
                                this.setState({
                                    addFiltersMaxWidth: 500
                                })
                            }}
                        >
                            {TranslationController.translate("Dodatkowe filtry")}
                        </Button>
                    </div>

                    <AdditionalFilters
                        maxWidth={this.state.addFiltersMaxWidth}
                        onClose={() => {
                            this.setState({
                                addFiltersMaxWidth: 0
                            })
                        }}
                        orderTable={this.orderTable}
                    />

                    <Tabs className={"es-panel-tab-container"} value={this.state.tab} onChange={(e, v) => this.handleChangeTab(e, v)}>
                        <div className={"tab-wrapper"}>
                            <Tab onClick={() => this.handleChangeTab(null, 0)} label={TranslationController.translate("Lista")} />
                        </div>

                        {this.state.tabs.map((e, k) => {
                            return (
                                <div className={"tab-wrapper"}>
                                    <Tab onClick={() => this.handleChangeTab(e, k + 1)} key={k} label={e.id}/>

                                    <div onClick={() => {
                                        this.handleCloseTab(k)
                                    }} className={"es-panel-tab-close"}>
                                        <IconSemantic name={"close"}/>
                                    </div>
                                </div>
                            )
                        })}
                    </Tabs>

                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu icon='wrench'>
                            <TableMenu.Menu>
                                <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                                <TableMenu.Divider />
                                <TableMenu.Header>Akcje zbiorcze</TableMenu.Header>
                                <TableMenu.Item
                                    onClick={() => {
                                        this.setState({
                                            changeStatus: {}
                                        })
                                    }}
                                >
                                    <span className='text'>Zmień status</span>
                                </TableMenu.Item>
                                <TableMenu.Item
                                    onClick={() => {
                                        if (!this.orderTable.getSelectedState().length) {
                                            return SnackBar("Nie wybrano żadnego wiersza", "error")
                                        }

                                        return Confirm("Czy na pewno chcesz anulować zamówienie?", null, () => {
                                            this.setState({
                                                loading: true
                                            });

                                            api().post("/api/orders/cancel", {multi: true, userId: DATA_OBJECT.user_login, order: this.orderTable.getSelectedState().map(e => ({id: e.id}))})
                                                .then(r => {
                                                    this.setState({
                                                        loading: false,
                                                        tableSelectableState: [],
                                                    });

                                                    if (r.data.error) {
                                                        SnackBar(r.data.message, "error");
                                                    } else {
                                                        SnackBar("Anulowano zamówienia");

                                                        this.orderTable.setState({
                                                            data: null,
                                                        });

                                                        this.orderTable.init()
                                                    }
                                                })
                                        })
                                    }}
                                >
                                    <span className='text'>Anuluj zamówienia</span>
                                </TableMenu.Item>
                                <TableMenu.Item
                                    onClick={() => {
                                        if (!this.orderTable.getSelectedState().length) {
                                            return SnackBar("Nie wybrano żadnego wiersza", "error")
                                        }

                                        return Confirm("Czy na pewno chcesz wysłać komunikaty do systemu Frontier?", null, () => {
                                            api().post("/api/orders/frontier/request", {
                                                orderIds: this.orderTable.getSelectedState().map(e => e.id),
                                                status: "U",
                                                userId: DATA_OBJECT.user_login
                                            })
                                                .then(result => {
                                                    if (result.data.error) {
                                                        SnackBar(result.data.message, "error");
                                                    } else {
                                                        SnackBar("Wysłano komunikat");
                                                    }
                                                })
                                        })
                                    }}
                                >
                                    <span className='text'>Komunikat GATE (U)</span>
                                </TableMenu.Item>

                                <TableMenu.Item
                                    onClick={() => {
                                        if (!this.orderTable.getSelectedState().length) {
                                            return SnackBar("Nie wybrano żadnego wiersza", "error")
                                        }

                                        return Confirm("Czy na pewno chcesz zaktualizować wybrane zamówienia?", "", () => {
                                            this.setState({
                                                loading: true
                                            });

                                            return api().post("/api/orders/multiUpdate", {
                                                userId: DATA_OBJECT.user_login,
                                                orders: this.orderTable.getSelectedState().map(e => ({
                                                    id: e.id,
                                                    packList: true
                                                }))
                                            })
                                                .then(r => {
                                                    this.setState({
                                                        loading: false,
                                                        tableSelectableState: [],
                                                    });

                                                    this.orderTable.setState({
                                                        data: null,
                                                    });

                                                    this.orderTable.init()
                                                })
                                        })
                                    }}
                                >
                                    <span className='text'>Packlista > Tak</span>
                                </TableMenu.Item>

                                <TableMenu.Item
                                    onClick={() => {
                                        if (!this.orderTable.getSelectedState().length) {
                                            return SnackBar("Nie wybrano żadnego wiersza", "error")
                                        }

                                        return Confirm("Czy na pewno chcesz zaktualizować wybrane zamówienia?", "", () => {
                                            this.setState({
                                                loading: true
                                            });

                                            return api().post("/api/orders/multiUpdate", {
                                                orders: this.orderTable.getSelectedState().map(e => ({
                                                    id: e.id,
                                                    packList: false
                                                }))
                                            })
                                                .then(r => {
                                                    this.setState({
                                                        loading: false,
                                                        tableSelectableState: [],
                                                    });

                                                    this.orderTable.setState({
                                                        data: null,
                                                    });

                                                    this.orderTable.init()
                                                })
                                        })
                                    }}
                                >
                                    <span className='text'>Packlista > Nie</span>
                                </TableMenu.Item>
                                <TableMenu.Item
                                    onClick={() => this.transferOrderTo('k99')}
                                >
                                    <span className='text'>Przenieś do k99</span>
                                </TableMenu.Item>

                                <TableMenu.Item
                                    onClick={() => this.transferOrderTo('x99')}
                                >
                                    <span className='text'>Przenieś do x99</span>
                                </TableMenu.Item>

                                <TableMenu.Divider />
                                <TableMenu.Header>Export</TableMenu.Header>
                                <TableMenu.Item
                                    onClick={() => {
                                        this.setState({
                                            loading: true,
                                        });

                                        api().post("/api/orders/packNumberExport", {
                                            limit: 35000,
                                            currentPage: 1,
                                            filter: this.orderTable.state.appliedFilters,
                                        })
                                            .then(r => {
                                                this.setState({
                                                    loading: false,
                                                });

                                                if (r.data.error) {
                                                    return SnackBar(r.data.message, "error")
                                                }

                                                return saveToFile(r.data);
                                            })
                                    }}
                                >
                                    <span className='text'>Numery listów przewozowych</span>
                                </TableMenu.Item>

                                <TableMenu.Item
                                    onClick={() => {
                                        this.setState({
                                            modalTimingReport: true,
                                            timingReportFrom: formatDate(new Date().getTime()).dateNowSystem,
                                            timingReportTo: formatDate(new Date().getTime()).dateNowSystem,
                                            timingReportType: "type_parlour",
                                            timingReportRange: "sent_date"
                                        })
                                    }}
                                >
                                    <span className='text'>Raport czasowy</span>
                                </TableMenu.Item>

                                <TableMenu.Item>
                                    <span className='text'>Raport uniwersalny</span>

                                    <TableMenu.Menu>
                                        <div className={"uni-report-container"}>
                                            <div className={"uni-report-header"}>Wybierz kolumny do raportu</div>

                                            <div className={"uni-report-content-box"}>
                                                <div className={"uni-report-column"}>
                                                    <ul>
                                                        {this.exportFields.map((fieldValues, key) => {
                                                            return (
                                                                <li key={key}>
                                                                    <Checkbox key={key} onClick={(e) => {
                                                                        const field = fieldValues.field

                                                                        return !this.state.tableExportCols.includes(field) ? this.state.tableExportCols.push(field) : this.state.tableExportCols = this.state.tableExportCols.filter(col => col != field)
                                                                    }} label={fieldValues.label}/>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className={"uni-report-actions"}>
                                                <Button
                                                    color='blue'
                                                    content='Pobierz'
                                                    icon='download'
                                                    label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                                    onClick={() => this.uniExport()}
                                                />
                                            </div>
                                        </div>
                                    </TableMenu.Menu>
                                </TableMenu.Item>
                            </TableMenu.Menu>
                        </TableMenu>

                        <Menu.Menu position='right'>
                            <MenuButton
                                icon={"list"}
                                options={[{
                                    label: "GLS",
                                    onClick: () => {
                                        this.setState({
                                            tableSelectable: false,
                                            tableSelectableState: [],
                                            glsProtocol: true
                                        });
                                    }
                                }]}

                                label={"Pobierz protokół"}
                            />

                            <MenuButton
                                icon={"list"}
                                onClick={() => {
                                    this.setState({
                                        tableSelectable: false,
                                        tableSelectableState: [],
                                        generateProtocol: true
                                    });
                                }}
                                label={"Protokół przekazania"}
                            />

                            <MenuButton
                                icon={"warehouse"}
                                options={[{
                                    label: "GLS",
                                    onClick: () => {
                                        if (!this.orderTable.getSelectedState().length) {
                                            return SnackBar("Nie wybrano żadnego wiersza", "error")
                                        }
                                        const itemConfigArr = [];
                                        this.orderTable.getSelectedState().map((item, key) => {
                                            if(item.itemConfigs.length > 0) itemConfigArr.push(item.id);
                                        })

                                        return itemConfigArr.length > 0 ?
                                            ModalComponent("Zamówienie zawiera produkt z Grawerem",
                                                `Zamówienia z grawerami: 
                                            ${itemConfigArr.map((item, key) => {
                                                    return item
                                                })} 
                                            Czy produkt został już wygrawerowany ?`, ()=> {
                                                    Confirm("Czy na pewno chcesz wygenerować zbiorczy list przewozowy dla wybranych zamówień?", null, () => {
                                                        this.setState({
                                                            loading: true
                                                        });

                                                        api().post("/api/orders/delivery/multiCreate", {orders: this.orderTable.getSelectedState(), userId: DATA_OBJECT.user_login, type: "gls"})
                                                        .then(r => {
                                                            this.setState({
                                                                loading: false
                                                            });

                                                            if (r.data.error) {
                                                                SnackBar(r.data.message, "error")
                                                                return false;
                                                            } else {
                                                                SnackBar("Wygenerowano zbiorczy list przewozowy.");

                                                                const arrayBuffer = Uint8Array.from(r.data.label.data).buffer;
                                                                const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                                const blobUrl = URL.createObjectURL(blob);

                                                                window.open(blobUrl, "_blank")
                                                            }
                                                        }).then(() => {
                                                          this.setState({data: null});
                                                          this.orderTable.init();
                                                        }).catch(() => {
                                                            this.setState({
                                                                loading: false
                                                            });
                                                            SnackBar("Wystąpił błąd podczas generowania listu przewozowego", "error")
                                                        });
                                                    })
                                                })
                                            :
                                            Confirm("Czy na pewno chcesz wygenerować zbiorczy list przewozowy dla wybranych zamówień?", null, () => {
                                                this.setState({
                                                    loading: true
                                                });

                                                api().post("/api/orders/delivery/multiCreate", {orders: this.orderTable.getSelectedState(), userId: DATA_OBJECT.user_login, type: "gls"})
                                                .then(r => {
                                                    this.setState({
                                                        loading: false
                                                    });

                                                    if (r.data.error) {
                                                        SnackBar(r.data.message, "error")
                                                        return false;
                                                    } else {
                                                        SnackBar("Wygenerowano zbiorczy list przewozowy.");

                                                        const arrayBuffer = Uint8Array.from(r.data.label.data).buffer;
                                                        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                        const blobUrl = URL.createObjectURL(blob);

                                                        window.open(blobUrl, "_blank")
                                                    }
                                                }).then(() => {
                                                    this.setState({data: null});
                                                    this.orderTable.init();
                                                }).catch(() => {
                                                    this.setState({
                                                        loading: false
                                                    });
                                                    SnackBar("Wystąpił błąd podczas generowania listu przewozowego", "error")
                                                })
                                            })
                                    }
                                },{
                                    label: "DPD",
                                    onClick: () => {
                                        if (!this.orderTable.getSelectedState().length) {
                                            return SnackBar("Nie wybrano żadnego wiersza", "error")
                                        }
                                        const itemConfigArr = [];
                                        this.orderTable.getSelectedState().map((item, key) => {
                                            if(item.itemConfigs.length > 0) itemConfigArr.push(item.id);
                                        })

                                        return itemConfigArr.length > 0 ?
                                            ModalComponent("Zamówienie zawiera produkt z Grawerem",
                                                `Zamówienia z grawerami: 
                                            ${itemConfigArr.map((item, key) => {
                                                    return item
                                                })} 
                                            Czy produkt został już wygrawerowany ?`, ()=> {
                                                    Confirm("Czy na pewno chcesz wygenerować zbiorczy list przewozowy dla wybranych zamówień?", null, () => {
                                                        this.setState({
                                                            loading: true
                                                        });

                                                        api().post("/api/orders/delivery/multiCreate", {orders: this.orderTable.getSelectedState(), userId: DATA_OBJECT.user_login, type: "dpd"})
                                                            .then(r => {
                                                                this.setState({
                                                                    loading: false
                                                                });

                                                                if (r.data.error) {
                                                                    if (r.data.data && r.data.data.validation && r.data.data.validation.ValidationInfo && r.data.data.validation.ValidationInfo.length) {
                                                                        r.data.data.validation.ValidationInfo.forEach((e) => {
                                                                            SnackBar(e.Info, "error")
                                                                        });

                                                                        return false;
                                                                    }

                                                                    SnackBar(r.data.message, "error");
                                                                } else {
                                                                    SnackBar("Wygenerowano zbiorczy list przewozowy.");
                                                                }
                                                            })
                                                    })
                                                })
                                            :
                                            Confirm("Czy na pewno chcesz wygenerować zbiorczy list przewozowy dla wybranych zamówień?", null, () => {
                                                this.setState({
                                                    loading: true
                                                });

                                                api().post("/api/orders/delivery/multiCreate", {orders: this.orderTable.getSelectedState(), userId: DATA_OBJECT.user_login, type: "dpd"})
                                                    .then(r => {
                                                        this.setState({
                                                            loading: false
                                                        });

                                                        if (r.data.error) {
                                                            if (r.data.data && r.data.data.validation && r.data.data.validation.ValidationInfo && r.data.data.validation.ValidationInfo.length) {
                                                                r.data.data.validation.ValidationInfo.forEach((e) => {
                                                                    SnackBar(e.Info, "error")
                                                                });

                                                                return false;
                                                            }

                                                            SnackBar(r.data.message, "error");
                                                        } else {
                                                            SnackBar("Wygenerowano zbiorczy list przewozowy.");
                                                        }
                                                    })
                                        })
                                    }
                                }, {
                                    label: "INPOST",
                                    onClick: () => {
                                        if (!this.orderTable.getSelectedState().length) {
                                            return SnackBar("Nie wybrano żadnego wiersza", "error")
                                        }
                                        const itemConfigArr = [];
                                        this.orderTable.getSelectedState().map((item, key) => {
                                            if(item.itemConfigs.length > 0) itemConfigArr.push(item.id);
                                        })

                                        return itemConfigArr.length > 0 ?
                                            ModalComponent("Zamówienie zawiera produkt z Grawerem",
                                            `Zamówienia z grawerami: 
                                            ${itemConfigArr.map((item, key) => {
                                                return item
                                            })} 
                                            Czy produkt został już wygrawerowany ?`, ()=> {
                                                    Confirm("Czy na pewno chcesz wygenerować zbiorczy list przewozowy dla wybranych zamówień?", null, () => {
                                                        this.setState({
                                                            loading: true
                                                        });

                                                        api().post("/api/orders/delivery/multiCreate", {orders: this.orderTable.getSelectedState(), userId: DATA_OBJECT.user_login, type: "inpost_courier"})
                                                            .then(r => {
                                                                this.setState({
                                                                    loading: false
                                                                });

                                                                if (r.data.error) {
                                                                    return SnackBar(r.data.message, "error")
                                                                }

                                                                SnackBar("Wygenerowano zbiorczy list przewozowy.");
                                                            })
                                                    })
                                            }) :
                                         Confirm("Czy na pewno chcesz wygenerować zbiorczy list przewozowy dla wybranych zamówień?", null, () => {
                                            this.setState({
                                                loading: true
                                            });

                                            api().post("/api/orders/delivery/multiCreate", {orders: this.orderTable.getSelectedState(), userId: DATA_OBJECT.user_login, type: "inpost_courier"})
                                                .then(r => {
                                                    this.setState({
                                                        loading: false
                                                    });

                                                    if (r.data.error) {
                                                        return SnackBar(r.data.message, "error")
                                                    }

                                                    SnackBar("Wygenerowano zbiorczy list przewozowy.");
                                                })
                                        })
                                    }
                                }]}
                                label={"Wysyłka zbiorcza"}
                            />

                            <MenuButton
                                icon={"envelope"}
                                label={"Mail opóźniona wysyłka"}
                                onClick={() => {
                                    if (!this.orderTable.getSelectedState().length) {
                                        return SnackBar("Nie wybrano żadnego wiersza", "error")
                                    }

                                    return Confirm("Czy na pewno chcesz wysłać powiadomienie do wybranych zamówień?", null, () => {
                                        this.setState({
                                            loading: true
                                        });

                                        api().post("/api/mailer/sendExternalMail",
                                            {
                                                multi: true,
                                                ordersIds: this.orderTable.getSelectedState().map(e => e.id),
                                                variant: "delivery_delay/new",
                                                userId:  DATA_OBJECT.user_login
                                            })
                                            .then(result => {
                                                this.setState({
                                                    loading: false
                                                });

                                                if (result.data.success) {
                                                    SnackBar("Wysłano powiadomienia e-mail");
                                                } else {
                                                    SnackBar("Błąd podczas wysyłania powiadomienia. Sprawdź konfigurację.", "error");
                                                }
                                            })
                                    })
                                }}
                            />

                            <MenuButton
                                icon={"search"}
                                label={this.state.internalOrders ? "Ukryj zamówienia wew." : "Pokaż zamówienia wew."}
                                onClick={() => {
                                    localStorage.setItem("is_internal", this.state.internalOrders ? false : true);

                                    this.setState({
                                        internalOrders: this.state.internalOrders ? false : true
                                    }, () => {
                                        this.orderTable.setState({
                                            data: null
                                        });

                                        this.orderTable.init()
                                    });
                                }}
                            />

                            <MenuButton
                                icon={"cloud download"}
                                label={"Import zamówień wewnętrznych"}
                                csvImport={true}
                                csvStructure={[
                                    {column: "Sku"}, {column: "Qty"}
                                ]}
                                csvImportOnUpdate={(data) => {
                                    this.setState({
                                        loading: true
                                    });

                                    return api().post("/api/order/internalCsvOrder", {data})
                                        .then((data) => {
                                            this.setState({
                                                loading: false,
                                                data: null,
                                                websiteFilter: [],
                                                dataCount: null,
                                                tableSelectable: false,
                                                tableSelectableState: [],
                                                tableExportCols: []
                                            });

                                            this.orderTable.setState({
                                                data: null
                                            });

                                            this.orderTable.init();

                                            return data
                                        })
                                }}
                            />

                            <MenuButton
                                icon={"list"}
                                onClick={() => {
                                    return Confirm("Czy na pewno chcesz utworzyć listę pakową z wybranych zamówień?", "", () => {
                                        if (!this.orderTable.state.appliedFilters) {
                                            return SnackBar("Nie wybrano żadnego filtra", "error")
                                        }

                                        if (!this.orderTable.state.appliedFilters.length) {
                                            return SnackBar("Nie wybrano żadnego filtra", "error")
                                        }

                                        return api().post("/api/depotCollection/insert", {
                                            selectedOrders: this.orderTable.getSelectedState().map(e => e.id),
                                            isSalonList: false,
                                            isOutletList: false,
                                            filters: this.orderTable.state.appliedFilters,
                                            userId: DATA_OBJECT["user"]["login"]
                                        }).then((r) => {
                                            if (r.data.error) {
                                                return SnackBar(r.data.message, "error");
                                            }

                                            SnackBar("Utworzono listę.");
                                        })
                                    })
                                }}
                                label={"Generuj listę pakową"}
                            />

                            <MenuButton
                                icon={"list"}
                                onClick={() => {
                                    return Confirm("Czy na pewno chcesz utworzyć listę pakową z wybranych zamówień?", "", () => {
                                        if (!this.orderTable.state.appliedFilters) {
                                            return SnackBar("Nie wybrano żadnego filtra", "error")
                                        }

                                        if (!this.orderTable.state.appliedFilters.length) {
                                            return SnackBar("Nie wybrano żadnego filtra", "error")
                                        }

                                        return api().post("/api/depotCollection/insert", {
                                            selectedOrders: this.orderTable.getSelectedState().map(e => e.id),
                                            isSalonList: true,
                                            isOutletList: false,
                                            userId: DATA_OBJECT["user"]["login"],
                                            filters: this.orderTable.state.appliedFilters
                                        }).then((r) => {
                                            if (r.data.error) {
                                                return SnackBar(r.data.message, "error");
                                            }

                                            SnackBar("Utworzono listę.");
                                        })
                                    })
                                }}
                                label={"Generuj listę pakową salony"}
                            />

                            <MenuButton
                                icon={"list"}
                                onClick={() => {
                                    return Confirm("Czy na pewno chcesz utworzyć listę pakową z wybranych zamówień?", "", () => {
                                        if (!this.orderTable.state.appliedFilters) {
                                            return SnackBar("Nie wybrano żadnego filtra", "error")
                                        }

                                        if (!this.orderTable.state.appliedFilters.length) {
                                            return SnackBar("Nie wybrano żadnego filtra", "error")
                                        }

                                        return api().post("/api/depotCollection/insert", {
                                            selectedOrders: this.orderTable.getSelectedState().map(e => e.id),
                                            isSalonList: false,
                                            isOutletList: true,
                                            userId: DATA_OBJECT["user"]["login"],
                                            filters: this.orderTable.state.appliedFilters
                                        }).then((r) => {
                                            if (r.data.error) {
                                                return SnackBar(r.data.message, "error");
                                            }

                                            SnackBar("Utworzono listę.");
                                        })
                                    })
                                }}
                                label={"Generuj listę pakową outlet"}
                            />

                            <MenuButton
                                icon={"list"}
                                onClick={() => {
                                    return Confirm("Czy na pewno chcesz utworzyć listę pakową z wybranych zamówień?", "", () => {
                                        if (!this.orderTable.state.appliedFilters) {
                                            return SnackBar("Nie wybrano żadnego filtra", "error")
                                        }

                                        if (!this.orderTable.state.appliedFilters.length) {
                                            return SnackBar("Nie wybrano żadnego filtra", "error")
                                        }

                                        return api().post("/api/depotCollection/insert", {
                                            selectedOrders: this.orderTable.getSelectedState().map(e => e.id),
                                            isSalonList: false,
                                            isOutletList: false,
                                            isE99List: true,
                                            userId: DATA_OBJECT["user"]["login"],
                                            filters: this.orderTable.state.appliedFilters
                                        }).then((r) => {
                                            if (r.data.error) {
                                                return SnackBar(r.data.message, "error");
                                            }

                                            SnackBar("Utworzono listę.");
                                        })
                                    })
                                }}
                                label={"Generuj listę pakową Esotiq omnichannel"}
                            />

                            <MenuButton
                                icon={"list"}
                                onClick={() => {
                                    return Confirm("Czy na pewno chcesz utworzyć listę pakową z wybranych zamówień?", "", () => {
                                        if (!this.orderTable.state.appliedFilters) {
                                            return SnackBar("Nie wybrano żadnego filtra", "error")
                                        }

                                        if (!this.orderTable.state.appliedFilters.length) {
                                            return SnackBar("Nie wybrano żadnego filtra", "error")
                                        }

                                        return api().post("/api/depotCollection/insert", {
                                            selectedOrders: this.orderTable.getSelectedState().map(e => e.id),
                                            isSalonList: true,
                                            isOutletList: false,
                                            isE99List: true,
                                            userId: DATA_OBJECT["user"]["login"],
                                            filters: this.orderTable.state.appliedFilters
                                        }).then((r) => {
                                            if (r.data.error) {
                                                return SnackBar(r.data.message, "error");
                                            }

                                            SnackBar("Utworzono listę.");
                                        })
                                    })
                                }}
                                label={"Generuj listę pakową Esotiq salony omnichannel"}
                            />
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        {this.state.loadingCount === 2 &&
                            <div style={{
                                display: this.state.tab === 0 ? "block" : "none"
                            }}>
                                <UITable
                                    dataFetch={{
                                        url: "/api/orders",
                                        options: {
                                            limit: 25,
                                            currentPage: 1,
                                            internalOrders: this.state.internalOrders,
                                            user: DATA_OBJECT["user"],
                                            useCache: true,
                                            orderDateRangeMonths: this.state.orderDateRangeMonths
                                        },
                                        callback: async (orders) => {
                                            await (() => new Promise(resolve => {
                                                api(true).post("/api/orders/bindOrderServices",  {
                                                    ordersIds: orders.data.map(e => e.id)
                                                })
                                                    .then(r => {
                                                        if (r && r.data && r.data.result) {
                                                            for (let [orderId, orderServices] of Object.entries(r.data.result)) {
                                                                orderServices.forEach(orderService => {
                                                                    orders.data.map(e => {
                                                                        if (parseInt(orderService.orderId) === parseInt(e.id) && orderService.status !== "rejected") {
                                                                            if (!e.sendFrom) {
                                                                                e.sendFrom = []
                                                                            }

                                                                            let isAlreadyIncludes = false;

                                                                            e.sendFrom.forEach(e => {
                                                                                if (e.parlour === orderService.parlour) {
                                                                                    isAlreadyIncludes = true;
                                                                                }
                                                                            })

                                                                            if (!isAlreadyIncludes) {
                                                                                e.sendFrom.push({
                                                                                    orderServiceId: orderService._id,
                                                                                    parlour: orderService.parlour,
                                                                                    status: orderService.status,
                                                                                    proceed: orderService.proceed
                                                                                })
                                                                            }
                                                                        }
                                                                    })
                                                                })
                                                            }

                                                            orders.data.map(order => {
                                                                if (r.data.result[order.id]) {
                                                                    order.orderService = r.data.result[order.id];
                                                                }
                                                            })
                                                        }
                                                        resolve();
                                                    })
                                            }))();

                                            await (() => new Promise(resolve => {
                                                api(true).post("/api/orders/bindComments",  {
                                                    ordersIds: orders.data.map(e => e.id)
                                                })
                                                    .then(r => {
                                                        if (r && r.data && r.data.result) {
                                                            for (let [orderId, comments] of Object.entries(r.data.result)) {
                                                                comments.forEach(comm => {
                                                                    orders.data.map(e => {
                                                                        if (parseInt(comm.orderId) === parseInt(e.id)) {
                                                                            e.commentCount = (e.commentCount || 0) + 1
                                                                        }
                                                                    })
                                                                })
                                                            }
                                                        }

                                                        orders.data.map(e => {
                                                            if (e.shippingDescription || e.addressShipping.description) {
                                                                e.commentCount = (e.commentCount || 0) + 1
                                                            }
                                                        })

                                                        resolve();
                                                    })
                                            }))();

                                            await (() => new Promise(resolve => {
                                                api().post("/api/orders/bindImages",  {
                                                    ordersIds: orders.data.map(e => e.id)
                                                })
                                                    .then(r => {
                                                        if (r && r.data && r.data.result) {
                                                            orders.data.map(e => {
                                                                if (r.data.result[e.id]) {
                                                                    e.items = r.data.result[e.id]
                                                                }
                                                            })
                                                        }

                                                        resolve();
                                                    })
                                            }))();

                                            if (this.orderTable) {
                                                this.orderTable.setState({
                                                    data: orders.data
                                                });
                                            }
                                        }
                                    }}
                                    ref={table => this.orderTable = table}
                                    selectable={this.state.tableSelectable}
                                    onRowSelect={(row) => {
                                        this.setState((prevState) => {
                                            const { selectedRows } = prevState;
                                            const isSelected = selectedRows.some(selectedRow => selectedRow.id === row.id);

                                            let newSelectedRows;
                                            if (isSelected) {
                                                newSelectedRows = selectedRows.filter(selectedRow => selectedRow.id !== row.id);
                                            } else {
                                                newSelectedRows = [...selectedRows, row];
                                            }

                                            return { selectedRows: newSelectedRows };
                                        });
                                    }}
                                    provider={this}
                                    rowStyle={(row) => {
                                        return ((row.status === "canceled" || !row.statusPayment) ? {
                                            opacity: 0.6
                                        } : null)
                                    }}
                                    columns={[
                                        {
                                            label: "ID",
                                            render: row => <div>
                                                {(row.cardNumber && row.cardNumber.length > 2) &&
                                                <div className={"order-club-badge"}>
                                                    <img src={require("../../resources/club.png")}/>
                                                </div>
                                                }

                                                {/*{((DATA_OBJECT["user"] && DATA_OBJECT["user"].login === "root")) &&*/}
                                                {/*<div>*/}
                                                {/*    <Button onClick={() => {*/}
                                                {/*        Confirm(`Czy na pewno chcesz usunąć ID ${row.id}?`, "", () => {*/}
                                                {/*            return api().post("/api/orders/removeByMongoId",  {*/}
                                                {/*                id: row._id,*/}
                                                {/*            })*/}
                                                {/*                .then(r => {*/}
                                                {/*                    window.location.reload();*/}
                                                {/*                })*/}
                                                {/*        })*/}
                                                {/*    }}>*/}
                                                {/*        REMOVE*/}
                                                {/*    </Button>*/}
                                                {/*</div>*/}
                                                {/*}*/}

                                                {(row.type === "allegro" || row.allegroData?.marketplace === "allegro") &&
                                                    <img src={AllegroLogo} style={{
                                                        width: 50
                                                    }}/>
                                                }

                                                {row.allegroData?.marketplace === "modivo" &&
                                                    <img src={ModivoLogo} style={{
                                                        width: 50
                                                    }}/>
                                                }

                                                {row.allegroData?.marketplace === "empik" &&
                                                    <img src={EmpikLogo} style={{
                                                        width: 50
                                                    }}/>
                                                }

                                                {row.allegroData?.marketplace === "wszystko" &&
                                                    <img src={WszystkoPl} style={{
                                                        width: 100
                                                    }}
                                                    />
                                                }

                                                {row.allegroData?.marketplace === "emagro" && 
                                                    <img src={EmagLogo} style={{
                                                        width: 75
                                                    }}/>
                                                }

                                                {row.isCashback &&
                                                <div className={"order-cashback-badge"}>
                                                    <img src={require("../../resources/cashback.png")}/>

                                                    <div className={"cb-title"}>
                                                        Cashback
                                                    </div>
                                                </div>
                                                }

                                                <div className={"es-order-id"}><span className={"value mainId"}>{row.id}</span></div>
                                                <div className={"es-order-id"}><span className={"label"}>{formatDate(row.createdAt).dateTimeSystem}</span></div>

                                                {row.type === "allegro" &&
                                                <div className={"es-order-id"}><span className={"label"}>{formatDate(row.allegroData.formData.payment.finishedAt).dateTimeSystem}</span></div>
                                                }

                                                {row.type === "allegro" &&
                                                <div className={"es-order-id"}><span className={"label"}>AllegroID:</span> <span className={"value"}>{row.allegroFormId}</span></div>
                                                }

                                                {row.allegroData?.baselinkerOrderId && 
                                                    <div className={"es-order-id"}><span className={"label"}>BL ID:</span> <span className={"value"}>{row.allegroData.baselinkerOrderId}</span></div>
                                                }
                                            </div>,
                                            width: 1,
                                            field: "id",
                                            filtersWidth: 840,
                                            filtersFlex: true,
                                            filtersColWidth: 200,
                                            filters: [
                                                {label: "Sortuj ID", condition: "in", type: "radio", forceField: "sort_id", options: [
                                                        {
                                                            value: "asc",
                                                            label: "Rosnąco"
                                                        }, {
                                                            value: "desc",
                                                            label: "Malejąco"
                                                        }
                                                    ]
                                                },
                                                {label: "Numer zamówienia (część lub pełny numer)", condition: "in", type: "text", objectField: "part_of_integer"},
                                                {label: "Stworzono", condition: "between", type: "date", forceField: "createdAt"},
                                                {label: "Data wysłania", condition: "between", type: "date", forceField: "delivery.createdTime"},
                                                {label: "Numer zamówienia", condition: "in", type: "in"},
                                                {label: "Marketplace", condition: "exists", type: "toggle", forceField: "allegroData"},
                                                {label: "Marketplace", condition: "in", type: "checkbox", forceField: "allegroData.marketplace", options: [
                                                        {
                                                            value: "allegro",
                                                            label: "Allegro"
                                                        },
                                                        {
                                                            value: "modivo",
                                                            label: "Modivo"
                                                        },
                                                        {
                                                            value: "empik",
                                                            label: "Empik"
                                                        },
                                                        {
                                                            value: "emagro",
                                                            label: "Emag"
                                                        }
                                                    ]
                                                },
                                                {label: "Numer Allegro", condition: "in", type: "in", forceField: "allegroFormId"},
                                                {label: "Typ zamówień", condition: "in", type: "checkbox", forceField: "parlourOrder", options: [
                                                        {
                                                            value: true,
                                                            label: "Salonowe"
                                                        }
                                                    ]
                                                },
                                                {label: "Typ zamówień", condition: "in", type: "checkbox", forceField: "onlineShopOrder", options: [
                                                        {
                                                            value: true,
                                                            label: "Sklep internetowy (mag. K99, E99, 157)"
                                                        }
                                                    ]
                                                },
                                                {label: "Numer BL", condition: "in", type: "in", forceField: "allegroData.baselinkerOrderId"},
                                                {label: "Typ zamówień", condition: "in", type: "checkbox", forceField: "webOrder", options: [
                                                        {
                                                            value: true,
                                                            label: "Web"
                                                        }
                                                    ]
                                                },
                                                {label: "Typ zamówień", condition: "in", type: "checkbox", forceField: "appOrder", options: [
                                                        {
                                                            value: true,
                                                            label: "Aplikacja"
                                                        }
                                                    ]
                                                },
                                                // {label: "Cashback", condition: "in", type: "checkbox", forceField: "isCashback", options: [
                                                //         {
                                                //             value: true,
                                                //             label: "Cashback"
                                                //         }
                                                //     ]
                                                // },
                                                {label: "Typ zamówień", condition: "in", type: "checkbox", forceField: "personalized", options: [
                                                        {
                                                            value: true,
                                                            label: "Personalizowane"
                                                        }
                                                    ]
                                                },
                                                {label: "Esotiq Club", condition: "in", type: "checkbox", forceField: "isClub", options: [
                                                        {
                                                            value: true,
                                                            label: "Esotiq Club"
                                                        }
                                                    ]
                                                },
                                                {label: "Kompletator", condition: "in", type: "checkbox", forceField: "orderPicker", options: [
                                                        {
                                                            value: "V1",
                                                            label: "V1"
                                                        },
                                                        {
                                                            value: "V2",
                                                            label: "V2"
                                                        }
                                                    ]
                                                },
                                                {label: "Typ zamówień", condition: "in", type: "checkbox", forceField: "e99157ParlourOrder", options: [
                                                        {
                                                            value: true,
                                                            label: "Wewnętrzne"
                                                        }
                                                    ]
                                                },
                                            ],
                                        },
                                        {
                                            label: "Wysyłka",
                                            width: 1,
                                            field: "sendFrom",
                                            filters: [
                                                {label: "Wysyłka z", condition: "in", type: "text", forceField: "sendFrom", uppercase: true},
                                                {label: "Punkt odbioru", condition: "in", type: "text", forceField: "shippingPoint.name", uppercase: true},
                                                {label: "Oczekuje od", condition: "eq", type: "text", forceField: "readyForClamByCustomerInParlour"}
                                            ],
                                            render: row => {
                                                if (!row.id) {
                                                    row.id = 0;
                                                }

                                                let isParlourOrder = false;

                                                if (row.id.toString().charAt(0) === "2") {
                                                    isParlourOrder = true;
                                                }

                                                if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse.toLowerCase() === "k99") {
                                                    isParlourOrder = false;
                                                }

                                                if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse.toLowerCase() === "e99") {
                                                    isParlourOrder = false;
                                                }

                                                if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse.toLowerCase() === "x99") {
                                                    isParlourOrder = true;
                                                }

                                                if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse.toLowerCase() === "n32") {
                                                    isParlourOrder = true;
                                                }

                                                if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse.toLowerCase() === "salonowe") {
                                                    isParlourOrder = true;
                                                }

                                                return (
                                                    <div className={"es-order-shippingType"}>
                                                        {isParlourOrder ?
                                                            <div className={"salon-label"}>
                                                                salon
                                                            </div>
                                                            : <div className={"shipp-label"}></div>
                                                        }

                                                        <div>
                                                            <div className={"parlour-completation-place"}>
                                                                <div className={"place-label"}>{TranslationController.translate("Wysyłka z")}:</div>
                                                                <div className={"place-value"}>
                                                                    {row.sendFrom ?
                                                                        <div>
                                                                            {row.sendFrom.map((service, serviceKey) => {
                                                                                    if (serviceKey > 2) {
                                                                                        return;
                                                                                    }

                                                                                    return (
                                                                                        <div
                                                                                            onClick={() => {
                                                                                                if (service.status === "send" || row.statusShipping === "sendToCustomer" || row.statusShipping === "send") {
                                                                                                    row.orderService.forEach(orderService => {
                                                                                                        let pickupNumber = orderService.pickupNumber;

                                                                                                        if (!pickupNumber) {
                                                                                                            if (row.delivery && row.delivery.type === "dpd" && row.delivery.data && row.delivery.data.pickupNumber) {
                                                                                                                pickupNumber = row.delivery.data.pickupNumber
                                                                                                            }
                                                                                                        }

                                                                                                        if (row.country === "de") {
                                                                                                            if (orderService._id === service.orderServiceId && pickupNumber) {
                                                                                                                window.open(`https://www.dhl.de/de/privatkunden/dhl-sendungsverfolgung.html?piececode=${pickupNumber}`, '_blank')
                                                                                                            }
                                                                                                        } else {
                                                                                                            if (orderService._id === service.orderServiceId && pickupNumber) {
                                                                                                                const url =  orderService?.provider === "gls" ? `https://gls-group.eu/PL/pl/sledzenie-paczek?match=${pickupNumber}` : `https://tracktrace.dpd.com.pl/parcelDetails?typ=1&p1=${pickupNumber}`;
                                                                                                                window.open(url, '_blank')
                                                                                                            }
                                                                                                        }

                                                                                                    })

                                                                                                    if (row.delivery && row.delivery.type === "inpost") {
                                                                                                        if (row.delivery.data) {
                                                                                                            api().post("/api/orders/getInpotTracking", {orderId: row.id})
                                                                                                                .then(r => {
                                                                                                                    if (r && r.data && r.data.trackingNumber) {
                                                                                                                        window.open(`https://inpost.pl/sledzenie-przesylek?number=${r.data.trackingNumber}`, '_blank')
                                                                                                                    }
                                                                                                                })
                                                                                                        }
                                                                                                    }

                                                                                                    if (row.delivery && row.delivery.type === "ruch") {
                                                                                                        if (row.delivery.data) {
                                                                                                            window.open(`https://www.orlenpaczka.pl/sledz-paczke/?numer=${row.delivery.pickupNumber}`, '_blank')
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                            className={`place-value-wrapper ${service.status === "send" && "place-sended"}`} key={serviceKey}>
                                                                                            <div>
                                                                                                {service.status === "send" &&
                                                                                                <IconSemantic name={"check"}/>
                                                                                                }

                                                                                                <span>
                                                                                {service.parlour.toString().toUpperCase()}
                                                                            </span>

                                                                                                {service.status === "send" &&
                                                                                                <div className={"tracking-label"}>
                                                                                                    <IconSemantic name={"truck"}/>
                                                                                                    <div>Tracking</div>
                                                                                                </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                            {row.sendFrom.length > 2 &&
                                                                                <div onClick={() => {
                                                                                    this.setState({
                                                                                        shippingFromListModal: row
                                                                                    })
                                                                                }} className={"place-value-wrapper order-send-from-more-label"}>
                                                                                    ...+({row.sendFrom.length - 2})
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                         : <div className={"sync-label"}>---</div>}
                                                                </div>
                                                            </div>

                                                            {/*{row.sendTo &&*/}
                                                            {/*<div className={"parlour-completation-place-to"}>*/}
                                                            {/*<div className={"place-label"}>{TranslationController.translate("Wysyłka do:")}</div>*/}
                                                            {/*<div className={"place-value"}>*/}
                                                            {/*{row.sendTo ? row.sendTo.map((service, serviceKey) => {*/}
                                                            {/*return (*/}
                                                            {/*<div className={`place-value-wrapper ${service.fullDelivered && "place-sended"}`} key={serviceKey}>*/}
                                                            {/*{service.fullDelivered &&*/}
                                                            {/*<IconSemantic name={"check"}/>*/}
                                                            {/*}*/}

                                                            {/*<span>*/}
                                                            {/*{service.parlour || TranslationController.translate("Klient")}*/}
                                                            {/*</span>*/}
                                                            {/*</div>*/}
                                                            {/*)*/}
                                                            {/*}) : <div className={"sync-label"}>{TranslationController.translate("Synchronizacja")}</div>}*/}
                                                            {/*</div>*/}
                                                            {/*</div>*/}
                                                            {/*}*/}

                                                            <div className={"parlour-shipping-place"}>
                                                                {(row.shippingPoint && row.shippingPoint.name) &&
                                                                <React.Fragment>
                                                                    <div className={"place-label"}>{TranslationController.translate("Punkt odbioru")}</div>
                                                                    <div className={"place-value"}>
                                                                        <span>{row.shippingPoint.name}</span>
                                                                    </div>
                                                                </React.Fragment>
                                                                }

                                                                {/*{row.shippingPlace &&*/}
                                                                {/*<React.Fragment>*/}
                                                                {/*<div className={"place-label"}>{TranslationController.translate("Odbiór w salonie")}</div>*/}
                                                                {/*<div className={"place-value"}>*/}
                                                                {/*<span>{row.shippingPlace}</span>*/}
                                                                {/*</div>*/}
                                                                {/*</React.Fragment>*/}
                                                                {/*}*/}

                                                                {row.readyForClamByCustomerInParlour &&
                                                                <React.Fragment>
                                                                    <div>
                                                                        <div className={"place-label"}>Gotowe do odbioru:</div>
                                                                        <div className={"little-info-value"}>
                                                                            {formatDate(row.readyForClamByCustomerInParlour).dateTimeSystem}
                                                                        </div>
                                                                    </div>

                                                                    {!row.claimedByCustomer &&
                                                                    <div>
                                                                        <div className={"place-label"}>Oczekuje od:</div>
                                                                        <div className={"little-info-value"}>
                                                                            {daysDiff(row.readyForClamByCustomerInParlour, new Date().getTime())} dni
                                                                        </div>
                                                                    </div>
                                                                    }
                                                                </React.Fragment>
                                                                }

                                                                {row.claimedByCustomer &&
                                                                <div>
                                                                    <div className={"place-label"}>Odebrane przez klienta:</div>
                                                                    <div className={"little-info-value"}>
                                                                        {formatDate(row.claimedByCustomer).dateTimeSystem}
                                                                    </div>
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        },
                                        {
                                            label: "Status",
                                            width: 2,
                                            render: row => {
                                                if (!row.id) {
                                                    row.id = 0;
                                                }

                                                let isParlourOrder = false;

                                                if (row.id.toString().charAt(0) === "2") {
                                                    isParlourOrder = true;
                                                }

                                                if (row.id.toString().charAt(0) === "4") {
                                                    isParlourOrder = true;
                                                }

                                                if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse.toLowerCase() === "k99") {
                                                    isParlourOrder = false;
                                                }

                                                if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse.toLowerCase() === "x99") {
                                                    isParlourOrder = true;
                                                }

                                                if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse.toLowerCase() === "n32") {
                                                    isParlourOrder = true;
                                                }

                                                if (row.additionalOptions && row.additionalOptions.forceWarehouse && row.additionalOptions.forceWarehouse.toLowerCase() === "salonowe") {
                                                    isParlourOrder = true;
                                                }

                                                return (
                                                    <div>
                                                        <div className={"es-order-satus-display"}><span className={"label"}>{TranslationController.translate("Status")}</span> <span style={{background: row.status === "canceled" ? new OrderStatusesHelper().getColor("canceled") : !row.statusPayment ? "#a1a1a1" : new OrderStatusesHelper().getColor(row.status), color: new OrderStatusesHelper().getFontColor(row.status)}} className={"value"}>{new OrderStatusesHelper().getLabel(row.status)}</span></div>
                                                        {(row.orderPicker !== 'V2' || row.country === 'de') && <div className={"es-order-satus-display"}><span className={"label"}>{TranslationController.translate("GATE")}</span> <span style={{background: row.status === "canceled" ? new OrderStatusesHelper().getColor("canceled") : !row.statusPayment ? "#a1a1a1" : new OrderStatusesHelper().getColor(row.gateStatus || null), color: new OrderStatusesHelper().getFontColor(row.gateStatus || null)}} className={"value"}>{new OrderStatusesHelper().getLabel(row.gateStatus || null)}</span></div>}
                                                        {row.orderPicker !== 'V2' && <div className={"es-order-satus-display"}><span className={"label"}>{TranslationController.translate("WMS")}</span> <span style={{background: row.status === "error" ? new OrderStatusesHelper().getColor("error") : !row.statusPayment ? "#a1a1a1" : new OrderStatusesHelper().getColor(row.wmsStatus || null), color: new OrderStatusesHelper().getFontColor(row.wmsStatus || null)}} className={"value"}>{new OrderStatusesHelper().getLabel(row.wmsStatus || null)}</span></div>}
                                                        {row.reservationStatus &&
                                                            <div className={"es-order-satus-display"}><span className={"label"}>{TranslationController.translate("Rezerwacje")}</span> <span style={{background: row.status === "canceled" ? new OrderStatusesHelper().getColor("canceled") : !row.statusPayment ? "#a1a1a1" : new OrderStatusesHelper().getColor(row.reservationStatus), color: new OrderStatusesHelper().getFontColor(row.reservationStatus)}} className={"value"}>{new OrderStatusesHelper().getLabel(row.reservationStatus)}</span></div>
                                                        }
                                                        <div className={"es-order-satus-display"}>
                                                            <div className={"label"}>
                                                                {TranslationController.translate("Wysyłka")}
                                                            </div>
                                                            <span style={{background: row.status === "canceled" ? new OrderStatusesHelper().getColor("canceled") : !row.statusPayment ? "#a1a1a1" : new OrderStatusesHelper().getColor(row.statusShipping), color: new OrderStatusesHelper().getFontColor(row.statusShipping)}} className={"value"}>{new OrderStatusesHelper().getLabel(row.statusShipping)}</span></div>
                                                        {row.statusCompletation && (isParlourOrder || row.id.toString().charAt(0) === "8") &&
                                                        <div className={"es-order-satus-display"}><span className={"label"}>{TranslationController.translate("Kompletacja")}</span> <span style={{background: row.status === "canceled" ? new OrderStatusesHelper().getColor("canceled") : !row.statusPayment ? "#a1a1a1" : new OrderStatusesHelper().getColor(row.statusCompletation), color: new OrderStatusesHelper().getFontColor(row.statusCompletation)}} className={"value"}>{new OrderStatusesHelper().getLabel(row.statusCompletation)}</span></div>
                                                        }

                                                        {row.ipwStatus &&
                                                            <div className={"es-order-satus-display"}><span className={"label"}>{TranslationController.translate("IPW")}</span> <span style={{background: new OrderStatusesHelper().getColor(row.ipwStatus), color: new OrderStatusesHelper().getFontColor(row.ipwStatus)}} className={"value"}>{new OrderStatusesHelper().getLabel(row.ipwStatus)}</span></div>
                                                        }

                                                        {(DATA_OBJECT["user"] && DATA_OBJECT["user"].login === "root") &&
                                                        <div className={"es-order-satus-display"}><span className={"label"}>{TranslationController.translate("Kompletacja")}</span> <span style={{background: row.status === "canceled" ? new OrderStatusesHelper().getColor("canceled") : !row.statusPayment ? "#a1a1a1" : new OrderStatusesHelper().getColor(row.statusCompletation), color: new OrderStatusesHelper().getFontColor(row.statusCompletation)}} className={"value"}>{new OrderStatusesHelper().getLabel(row.statusCompletation)}</span></div>
                                                        }

                                                        {((DATA_OBJECT["user"] && DATA_OBJECT["user"].login === "root")) &&
                                                            <React.Fragment>
                                                                <div className={"es-order-satus-display"}><span className={"label"}>{TranslationController.translate("Sync")}</span> <span style={{background: row.completationStopped ? new OrderStatusesHelper().getColor("S") : row.status === "canceled" ? new OrderStatusesHelper().getColor("canceled") : !row.statusPayment ? "#a1a1a1" : new OrderStatusesHelper().getColor(row.statusSynchro ? row.statusSynchro : null), color: new OrderStatusesHelper().getFontColor(row.statusSynchro ? row.statusSynchro : null)}} className={"value"}>{row.completationStopped ? new OrderStatusesHelper().getLabel("S") : new OrderStatusesHelper().getLabel(row.statusSynchro ? row.statusSynchro : null)}</span></div>
                                                                <div className={"es-order-satus-display"}><span className={"label"}>{TranslationController.translate("Portal")}</span> <span style={{background: row.status === "canceled" ? new OrderStatusesHelper().getColor("canceled") : !row.statusPayment ? "#a1a1a1" : new OrderStatusesHelper().getColor(row.storeName), color: new OrderStatusesHelper().getFontColor(row.storeName), textTransform: "capitalize"}} className={"value"}>{row.brand} <span style={{textTransform: "uppercase", marginLeft: 3}}>{row.lang}</span></span></div>
                                                                <div className={"es-order-satus-display"}><span className={"label"}>{TranslationController.translate("Kraj")}</span> <span style={{background: row.status === "canceled" ? new OrderStatusesHelper().getColor("canceled") : !row.statusPayment ? "#a1a1a1" : new OrderStatusesHelper().getColor(row.storeName), color: new OrderStatusesHelper().getFontColor(row.storeName), textTransform: "uppercase"}} className={"value"}>{row.country}</span></div>
                                                            </React.Fragment>
                                                        }

                                                        {((DATA_OBJECT["user"] && DATA_OBJECT["user"].login === "root")) && <div className={"es-order-satus-display"}><span
                                                            className={"label"}>{TranslationController.translate("Kompletator")}</span>
                                                            <span style={{
                                                                background: row.status === "canceled" ? new OrderStatusesHelper().getColor("canceled") : !row.statusPayment ? "#a1a1a1" : new OrderStatusesHelper().getColor(row.storeName),
                                                                color: new OrderStatusesHelper().getFontColor(row.storeName),
                                                                textTransform: "uppercase"
                                                            }} className={"value"}>{row.orderPicker || "V1"}</span>
                                                        </div>}
                                                    </div>
                                                )
                                            },
                                            field: "status",
                                            filtersWidth: 1120,
                                            filtersFlex: true,
                                            filtersColWidth: 200,
                                            filters: [
                                                {
                                                    label: "Kraj",
                                                    condition: "in",
                                                    type: "text",
                                                    forceField: "country"
                                                },
                                                {
                                                    label: "Język",
                                                    condition: "in",
                                                    type: "text",
                                                    forceField: "lang"
                                                },
                                                {
                                                    label: "Brand",
                                                    condition: "in",
                                                    type: "checkbox",
                                                    forceField: "brand",
                                                    options: [
                                                        {
                                                            value: "esotiq",
                                                            label: "Esotiq"
                                                        },
                                                        {
                                                            value: "henderson",
                                                            label: "Henderson"
                                                        },
                                                        {
                                                            value: "finalsale",
                                                            label: "Finalsale"
                                                        },
                                                        {
                                                            value: "vosedo",
                                                            label: "Vosedo"
                                                        }
                                                    ]
                                                },
                                                {label: "Sync", condition: "in", type: "checkbox", forceField: "statusSynchro", options: [
                                                        {
                                                            value: "process",
                                                            label: new OrderStatusesHelper().statuses.process.label
                                                        }, {
                                                            value: "solved",
                                                            label: new OrderStatusesHelper().statuses.solved.label
                                                        }
                                                    ]
                                                },
                                                {label: "Status", condition: "in", type: "checkbox", forceField: "status", options: [
                                                        {
                                                            value: "completed",
                                                            label: new OrderStatusesHelper().statuses.completed.label
                                                        }, {
                                                            value: "active",
                                                            label: new OrderStatusesHelper().statuses.active.label
                                                        },
                                                        {
                                                            value: "canceled",
                                                            label: new OrderStatusesHelper().statuses.canceled.label
                                                        },
                                                        {
                                                            value: "invoiced",
                                                            label: new OrderStatusesHelper().statuses.invoiced.label
                                                        }
                                                    ]
                                                },
                                                {label: "GATE", condition: "in", type: "checkbox", forceField: "gateStatus", options: [
                                                        {
                                                            value: "canceled",
                                                            label: new OrderStatusesHelper().statuses.canceled.label
                                                        },
                                                        {
                                                            value: null,
                                                            label: "Brak danych"
                                                        },
                                                        {
                                                            value: "error",
                                                            label: new OrderStatusesHelper().statuses.error.label
                                                        },
                                                        {
                                                            value: "completed",
                                                            label: new OrderStatusesHelper().statuses.completed.label
                                                        },
                                                        {
                                                            value: "process",
                                                            label: new OrderStatusesHelper().statuses.process.label
                                                        },
                                                        {
                                                            value: "partialProcess",
                                                            label: new OrderStatusesHelper().statuses.partialProcess.label
                                                        },
                                                        {
                                                            value: "stopped",
                                                            label: new OrderStatusesHelper().statuses.stopped.label
                                                        },
                                                        {
                                                            value: "waiting",
                                                            label: new OrderStatusesHelper().statuses.waiting.label
                                                        }
                                                    ]
                                                },
                                                {label: "WMS", condition: "in", type: "checkbox", forceField: "wmsStatus", options: [
                                                        {
                                                            value: "success",
                                                            label: new OrderStatusesHelper().statuses.success.label
                                                        },
                                                        {
                                                            value: null,
                                                            label: "Brak danych"
                                                        },
                                                        {
                                                            value: "error",
                                                            label: new OrderStatusesHelper().statuses.error.label
                                                        }
                                                    ]
                                                },
                                                {label: "IPW", condition: "in", type: "checkbox", forceField: "ipwStatus", options: [
                                                        {
                                                            value: "new",
                                                            label: new OrderStatusesHelper().statuses.new.label
                                                        }, {
                                                            value: "errored",
                                                            label: new OrderStatusesHelper().statuses.errored.label
                                                        },
                                                        {
                                                            value: "pending",
                                                            label: new OrderStatusesHelper().statuses.pending.label
                                                        },
                                                        {
                                                            value: "in_progress",
                                                            label: new OrderStatusesHelper().statuses.in_progress.label
                                                        }
                                                    ]
                                                },
                                                {label: "Rezerwacje", condition: "in", type: "checkbox", forceField: "reservationStatus", options: [
                                                        {
                                                            value: "COMPLETED",
                                                            label: new OrderStatusesHelper().statuses.COMPLETED.label
                                                        },
                                                        {
                                                            value: "IN_PROGRESS",
                                                            label: new OrderStatusesHelper().statuses.IN_PROGRESS.label
                                                        },
                                                        {
                                                            value: "WAITING",
                                                            label: new OrderStatusesHelper().statuses.WAITING.label
                                                        },
                                                    ]
                                                },
                                                {label: "Wysyłka", condition: "in", type: "checkbox", forceField: "statusShipping", options: [
                                                        {
                                                            value: "partialSended",
                                                            label: new OrderStatusesHelper().statuses.partialSended.label
                                                        }, {
                                                            value: "notSend",
                                                            label: new OrderStatusesHelper().statuses.notSend.label
                                                        }, {
                                                            value: "send",
                                                            label: new OrderStatusesHelper().statuses.send.label
                                                        },
                                                        {
                                                            value: "partialSendedToCustomer",
                                                            label: new OrderStatusesHelper().statuses.partialSendedToCustomer.label
                                                        },
                                                        {
                                                            value: "partialSendedToParlour",
                                                            label: new OrderStatusesHelper().statuses.partialSendedToParlour.label
                                                        },
                                                        {
                                                            value: "sendToCustomer",
                                                            label: new OrderStatusesHelper().statuses.sendToCustomer.label
                                                        },
                                                        {
                                                            value: "sendToParlour",
                                                            label: new OrderStatusesHelper().statuses.sendToParlour.label
                                                        },
                                                        {
                                                            value: "claimedByCustomer",
                                                            label: new OrderStatusesHelper().statuses.claimedByCustomer.label
                                                        },
                                                        {
                                                            value: "readyForClaimByCustomer",
                                                            label: new OrderStatusesHelper().statuses.readyForClaimByCustomer.label
                                                        },
                                                        {
                                                            value: "notClaimed",
                                                            label: new OrderStatusesHelper().statuses.notClaimed.label
                                                        },
                                                        {
                                                            value: "paused",
                                                            label: new OrderStatusesHelper().statuses.paused.label
                                                        }
                                                    ]
                                                },
                                                {label: "Kompletacja", condition: "in", type: "checkbox", forceField: "statusCompletation", options: [
                                                        {
                                                            value: "assigned",
                                                            label: new OrderStatusesHelper().statuses.assigned.label
                                                        }, {
                                                            value: "accepted",
                                                            label: new OrderStatusesHelper().statuses.accepted.label
                                                        }, {
                                                            value: "acceptedInChunk",
                                                            label: new OrderStatusesHelper().statuses.acceptedInChunk.label
                                                        }, {
                                                            value: "notSolved",
                                                            label: new OrderStatusesHelper().statuses.notSolved.label
                                                        }, {
                                                            value: "send",
                                                            label: new OrderStatusesHelper().statuses.send.label
                                                        }, {
                                                            value: "partialSended",
                                                            label: new OrderStatusesHelper().statuses.partialSended.label
                                                        }, {
                                                            value: null,
                                                            label: new OrderStatusesHelper().statuses.null.label
                                                        },
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            label: "Klient",
                                            width: 1,
                                            field: "addressShipping.firstname",
                                            filters: [
                                                {label: "Klient", condition: "like", type: "text"},
                                                {label: "Soft insert", condition: "in", type: "radio", forceField: "softInsert", options: [
                                                        {
                                                            value: true,
                                                            label: "Tak"
                                                        }
                                                    ]
                                                },
                                                {label: "Duplikat", condition: "in", type: "radio", forceField: "isDupliacted", options: [
                                                        {
                                                            value: true,
                                                            label: "Tak"
                                                        }
                                                    ]
                                                },
                                            ],
                                            render: row => <div style={{maxWidth: 300, wordWrap: "break-word", whiteSpace: "initial"}} className={"es-order-customerName"}>{row.addressShipping.firstname}</div>
                                        },
                                        {
                                            label: "Wysyłka do",
                                            field: "shippingToTime",
                                            width: 1,
                                            filters: [
                                                {label: "Sortuj", condition: "in", type: "radio", forceField: "sort_shippingToTime", options: [
                                                        {
                                                            value: "asc",
                                                            label: "Rosnąco"
                                                        }, {
                                                            value: "desc",
                                                            label: "Malejąco"
                                                        }
                                                    ]
                                                },
                                                {label: "Wysyłka do", condition: "between", type: "date", forceField: "shippingToTime"}
                                            ],
                                            render: row => <div>{row.shippingToTime ? formatDate(row.shippingToTime).dateNow : "---"}</div>
                                        },
                                        {
                                            label: "Wartość",
                                            width: 1,
                                            field: "price",
                                            filters: [
                                                {label: "Większa niż", condition: "greater_float", type: "text"},
                                                {label: "Mniejsza niż", condition: "less_float", type: "text"},
                                                {label: "Pomiędzy", condition: "between_float", type: "between"},
                                                {label: "Sortuj", condition: "in", type: "radio", forceField: "sort_price", options: [
                                                        {
                                                            value: "asc",
                                                            label: "Rosnąco"
                                                        }, {
                                                            value: "desc",
                                                            label: "Malejąco"
                                                        }
                                                    ]
                                                }
                                            ],
                                            render: row => <div className={"es-order-priceBox"}>{parseFloat(row.price).toFixed(2)} <span>{row.currency}</span></div>
                                        },
                                        {
                                            label: "Płatność i wysyłka",
                                            width: 1,
                                            field: "shippingMethodId",
                                            filtersWidth: 1000,
                                            filtersFlex: true,
                                            filtersColWidth: 200,
                                            translateX:"-50%",
                                            filters: [
                                                {label: "Metoda płatności", condition: "in", type: "checkbox", forceField: "paymentMethodId", options: this.state.paymentMethodsList.map(method => ({
                                                        value: method.externalId,
                                                        label: method.name
                                                    }))
                                                },
                                                {
                                                    label: "Metoda wysyłki",
                                                    condition: "in",
                                                    type: "checkbox",
                                                    forceField: "shippingMethodId",
                                                    options: this.state.shippingMethodsList.map(method => ({
                                                        value: method._id,
                                                        label: method.name + ` (${method.externalId})`
                                                    })),
                                                    columns: [{
                                                        title: TranslationController.translate("Esotiq"),
                                                        width: 200,
                                                        options: this.state.shippingMethodsList.filter(e => e.brand === "esotiq").map(method => ({
                                                            value: method._id,
                                                            label: method.name + ` (${method.externalId})`
                                                        }))
                                                    }, {
                                                        title: TranslationController.translate("Finalsale"),
                                                        width: 200,
                                                        options: this.state.shippingMethodsList.filter(e => e.brand === "finalsale").map(method => ({
                                                            value: method._id,
                                                            label: method.name + ` (${method.externalId})`
                                                        }))
                                                    }, {
                                                        title: TranslationController.translate("Henderson"),
                                                        width: 200,
                                                        options: this.state.shippingMethodsList.filter(e => e.brand === "henderson").map(method => ({
                                                            value: method._id,
                                                            label: method.name + ` (${method.externalId})`
                                                        }))
                                                    }]
                                                }
                                            ],
                                            render: row =>
                                                <div>
                                                    <div className={"es-order-payment-box"}>
                                                        <div>
                                                            <IconSemantic name={"payment"}/>
                                                        </div>

                                                        <div>
                                                            {/*<div className={"es-order-paymentLabel"}>{TranslationController.translate("Metoda płatności")}:</div>*/}
                                                            <div className={"es-order-paymentMethod"}>{row.paymentMethod}</div>
                                                            {row.paymentProvider &&
                                                            <div className={"es-order-paymentProvider"}>{row.paymentProvider}</div>
                                                            }
                                                        </div>
                                                    </div>

                                                    {row.shippingMethodId &&
                                                    <div className={"es-order-payment-box"}>
                                                        <div>
                                                            <IconSemantic name={"truck"}/>
                                                        </div>

                                                        <div>
                                                            {/*<div className={"es-order-paymentLabel"}>{TranslationController.translate("Metoda dostawy")}:</div>*/}
                                                            {(this.state.shippingMethods && this.state.shippingMethods[row.shippingMethodId]) &&
                                                            <div>
                                                                {/*{this.state.shippingMethods[row.shippingMethodId].iconUrl &&*/}
                                                                {/*<img className={"es-order-deliveryLogo"} src={this.state.shippingMethods[row.shippingMethodId].iconUrl} alt=""/>*/}
                                                                {/*}*/}

                                                                <div className={"es-order-paymentMethod"}>{this.state.shippingMethods[row.shippingMethodId].name}</div>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    }

                                                    {/*{(row.shippingPoint && row.shippingPoint.name) &&*/}
                                                    {/*<div className={"es-order-payment-box"}>*/}
                                                    {/*<div>*/}
                                                    {/*<IconSemantic name={"warehouse"}/>*/}
                                                    {/*</div>*/}

                                                    {/*<div>*/}
                                                    {/*<div className={"es-order-paymentLabel"}>{TranslationController.translate("Punkt odbioru")}:</div>*/}
                                                    {/*<div>*/}
                                                    {/*<div className={"es-order-paymentMethod"}>{row.shippingPoint.name}</div>*/}
                                                    {/*</div>*/}
                                                    {/*</div>*/}
                                                    {/*</div>*/}
                                                    {/*}*/}
                                                </div>
                                        },
                                        {
                                            label: "Status płat.",
                                            width: 1,
                                            field: "statusPayment",
                                            boolTrueIfValue: true,
                                            filters: [
                                                {label: "Status płatności", condition: "exists", type: "toggle"},
                                                {label: "Opłacone od (dni w zwyż)", condition: "eq", type: "text", forceField: "statusPaymentPaidFromDays"}
                                            ],
                                            onClick: row => {
                                                return Confirm("Czy na pewno chcesz zaktualizować to pole?", "", () => {
                                                    return api().post("/api/orders/update", {order: {
                                                            id: row.id,
                                                            statusPayment: row.statusPayment ? false : new Date().getTime(),
                                                        }, paymentUpdate: true, userId: DATA_OBJECT.user_login})
                                                        .then(r => {
                                                            this.orderTable.setState({
                                                                data: null
                                                            });

                                                            this.orderTable.init()

                                                            if (r.data.error) {
                                                                return SnackBar(r.data.message, "error")
                                                            }
                                                        })
                                                })
                                            },
                                            render: row => {
                                                return (
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}>
                                                        {row.statusPayment
                                                            ?
                                                            <div>
                                                                <div style={{
                                                                    textAlign: "center"
                                                                }}>
                                                                    <div className={"es-order-id"}><span className={"label"}>{formatDate(row.statusPayment).dateTimeSystem}</span></div>
                                                                </div>

                                                                <div style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center"
                                                                }}>
                                                                    <span style={{color: "#45b85d"}}><IconSemantic name={"check circle outline"}/></span>
                                                                </div>

                                                                {row.gateStatus === "process" &&
                                                                <div style={{
                                                                    textAlign: "center",
                                                                    fontWeight: 600,
                                                                    color: "#107324",
                                                                    fontSize: "0.9em"
                                                                }}>
                                                                    {TranslationController.translate("Opłacone od")} {daysDiff(row.statusPayment, new Date().getTime())} {TranslationController.translate("dni")}
                                                                </div>
                                                                }
                                                            </div>
                                                            :
                                                            <div>
                                                                <span style={{color: "#df3b4e"}}><IconSemantic name={"times circle outline"}/></span>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }
                                        },
                                        {
                                            label: "Pack lista",
                                            width: 1,
                                            field: "packList",
                                            bool: true,
                                            filters: [
                                                {label: "Pack lista", condition: "existsWithBoolean", type: "toggle", matchValue: {"1": true, "0": false}},
                                                {label: "Przydzielono do E99", condition: "existsWithBoolean", forceField: "assignedToK99", type: "toggle", matchValue: {"1": true, "0": false}}
                                            ],
                                            onClick: row => {
                                                return Confirm("Czy na pewno chcesz zaktualizować to pole?", "", () => {
                                                    return api().post("/api/orders/update", {order: {
                                                            id: row.id,
                                                            packList: row.packList ? false : true
                                                        }})
                                                        .then(r => {
                                                            this.orderTable.setState({
                                                                data: null
                                                            })

                                                            this.orderTable.init()

                                                            if (r.data.error) {
                                                                return SnackBar(r.data.message, "error")
                                                            }
                                                        })
                                                })
                                            }
                                        },
                                        {
                                            label: "Komentarze",
                                            field: "comments",
                                            filters: [
                                                {label: "Dowolny komentarz", condition: "exists", type: "toggle", hideFalseOption: true, forceField: "hasAnyComment"},
                                                {label: "Komentarz zwrotu", condition: "eq", type: "toggle", forceField: "hasRevertComment", matchValue: {"1": true, "0": false}}
                                            ],
                                            width: 1,
                                            center: true,
                                            onClick: row => {
                                                api().post("/api/orders/comments/get", {orderId: row.id})
                                                    .then((result) => {
                                                        this.setState({
                                                            orderData: row,
                                                            commnetModal: {
                                                                comments: result.data.comments,
                                                                id: row.id
                                                            }
                                                        })
                                                    })
                                            },
                                            render: row => <div className={"order-comment-table-wrapper"}>
                                                <Icon style={{
                                                    color: row.hasRevertComment ? "#ff0000" : null
                                                }}>comment</Icon>
                                                <div style={{
                                                    color: row.hasRevertComment ? "#a10000" : null
                                                }}>({row.commentCount || 0})</div>
                                            </div>
                                        },
                                        {
                                            label: "Koszyk",
                                            width: 1,
                                            center: true,
                                            field: "cart",
                                            filters: [
                                                {label: "Zakt. koszyk", condition: "exists", type: "toggle", forceField: "lastCartUpdate"},
                                            ],
                                            onClick: row => {
                                                // api().post("/api/states", {filter: [{
                                                //         label: "SKU",
                                                //         field: "sku",
                                                //         value: row.items.map(item => item.sku),
                                                //         condition: "in"
                                                //     }], images: true, limit: 500, currentPage: 1, simpleState: true})
                                                //     .then(r => {
                                                //         this.setState({
                                                //             stockData: r.data.data,
                                                //         })
                                                //     });

                                                this.setState({
                                                    cartModal: row
                                                })
                                            },
                                            render: row => <div className={"order-comment-table-wrapper"}>
                                                <Icon>shopping_cart</Icon>
                                                <div>({(row.items ? row.items.length : null )|| 0})</div>
                                            </div>
                                        },
                                        {
                                            label: "Podgląd",
                                            width: 1,
                                            center: true,
                                            onClick: row => this.handleAddTab(row),
                                            render: row => <div>
                                                <Icon>search</Icon>
                                            </div>
                                        },
                                        {
                                            label: "Otwórz",
                                            width: 1,
                                            center: true,
                                            onClick: row => {
                                                const orderData = {...row, ...{entity_id: 55}}
                                                window.open("#/order/" + orderData.id, '_blank')
                                            },
                                            render: row => <div>
                                                <Icon>tab</Icon>
                                            </div>
                                        }
                                    ]}
                                />
                            </div>
                        }

                        {this.state.tab > 0 &&
                            <div>
                                <OrderPreview
                                    orderData={this.state.tabs[this.state.tab - 1]}
                                    isFastPreview={true}
                                />
                            </div>
                        }
                    </Segment>
                </Container>

                {this.state.shippingFromListModal &&
                    <Modal dimmer={"inverted"} size={"large"} open={this.state.shippingFromListModal}
                           onClose={() => this.setState({shippingFromListModal: null})}>
                        <Modal.Header>Wysyłka z:</Modal.Header>
                        <Modal.Content>
                            <div className={"parlour-completation-place-to"}>
                                <div className={"place-value"}>
                                    <div>
                                        {this.state.shippingFromListModal.sendFrom.map((service, serviceKey) => {
                                            return (
                                                <div
                                                    onClick={() => {
                                                        if (service.status === "send" || this.state.shippingFromListModal.statusShipping === "sendToCustomer" || this.state.shippingFromListModal.statusShipping === "send") {
                                                            this.state.shippingFromListModal.orderService.forEach(orderService => {
                                                                let pickupNumber = orderService.pickupNumber;

                                                                if (!pickupNumber) {
                                                                    if (this.state.shippingFromListModal.delivery && this.state.shippingFromListModal.delivery.type === "dpd" && this.state.shippingFromListModal.delivery.data && this.state.shippingFromListModal.delivery.data.pickupNumber) {
                                                                        pickupNumber = this.state.shippingFromListModal.delivery.data.pickupNumber
                                                                    }
                                                                }

                                                                if (this.state.shippingFromListModal.country === "de") {
                                                                    if (orderService._id === service.orderServiceId && pickupNumber) {
                                                                        window.open(`https://www.dhl.de/de/privatkunden/dhl-sendungsverfolgung.html?piececode=${pickupNumber}`, '_blank')
                                                                    }
                                                                } else {
                                                                    if (orderService._id === service.orderServiceId && pickupNumber) {
                                                                        window.open(`https://tracktrace.dpd.com.pl/parcelDetails?typ=1&p1=${pickupNumber}`, '_blank')
                                                                    }
                                                                }

                                                            })

                                                            if (this.state.shippingFromListModal.delivery && this.state.shippingFromListModal.delivery.type === "inpost") {
                                                                if (this.state.shippingFromListModal.delivery.data) {
                                                                    api().post("/api/orders/getInpotTracking", {orderId: this.state.shippingFromListModal.id})
                                                                        .then(r => {
                                                                            if (r && r.data && r.data.trackingNumber) {
                                                                                window.open(`https://inpost.pl/sledzenie-przesylek?number=${r.data.trackingNumber}`, '_blank')
                                                                            }
                                                                        })
                                                                }
                                                            }

                                                            if (this.state.shippingFromListModal.delivery && this.state.shippingFromListModal.delivery.type === "ruch") {
                                                                if (this.state.shippingFromListModal.delivery.data) {
                                                                    window.open(`https://www.orlenpaczka.pl/sledz-paczke/?numer=${this.state.shippingFromListModal.delivery.pickupNumber}`, '_blank')
                                                                }
                                                            }
                                                        }
                                                    }}
                                                    className={`place-value-wrapper ${service.status === "send" && "place-sended"}`} key={serviceKey}>
                                                    <div>
                                                        {service.status === "send" &&
                                                        <IconSemantic name={"check"}/>
                                                        }

                                                        <span>
                                                                            {service.parlour.toString().toUpperCase()}
                                                                        </span>

                                                        {service.status === "send" &&
                                                        <div className={"tracking-label"}>
                                                            <IconSemantic name={"truck"}/>
                                                            <div>Tracking</div>
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({shippingFromListModal: null})} negative>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.cartModal &&
                    <Modal dimmer={"inverted"} size={"large"} open={this.state.cartModal}
                           onClose={() => this.setState({cartModal: false, orderData: null, stockData: null})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div>
                                <DisplayCart
                                    currency={this.state.cartModal.currency}
                                    provider={this}
                                    orderData={this.state.cartModal}
                                    orderId={this.state.cartModal.id}
                                    cartData={this.state.cartModal.items.map(e => {
                                        if (e.images && e.images[0]) {
                                            e.image = e.images[0].medium || e.images[0].file;
                                        }

                                        return e;
                                    })}
                                    editableBlocked={true}
                                    noDelete={true}
                                />
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({cartModal: false, orderData: null, stockData: null})} negative>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.generateProtocol &&
                <Modal dimmer={"inverted"} size={"medium"} open={this.state.generateProtocol} onClose={() => this.setState({generateProtocol: false, generateProtocolData: null, generateProtocolDataSelected: []})}>
                    <Modal.Header></Modal.Header>
                    <Modal.Content scrolling>
                        <div>
                            <Breadcrumb
                                crumbs={[
                                    {
                                        label: `Protokół przekazania ${this.state.dataProtocolCount ? `(${this.state.dataProtocolCount})` : ""}`
                                    }
                                ]}
                            />

                            <UITable
                                dataFetch={{
                                    url: "/api/orders/getDeliveryProtocol",
                                    options: {limit: 25, currentPage: 1},
                                    callback: (result, count) => {
                                        this.setState({
                                            dataProtocolCount: count
                                        })
                                    }
                                }}
                                onPage={50}
                                ref={table => this.protocolTable = table}
                                selectable={true}
                                // onRowSelect={selected => {
                                //     if (this.state.generateProtocolDataSelected.includes(selected)) {
                                //         this.state.generateProtocolDataSelected = this.state.generateProtocolDataSelected.filter(e => e !== selected)
                                //     } else {
                                //         this.state.generateProtocolDataSelected.push(selected)
                                //     }
                                // }}
                                provider={{
                                    setState: value => true
                                }}
                                columns={[
                                    {
                                        field: "createdAt",
                                        label: "Stworzono",
                                        center: true,
                                        filters: [
                                            {label: "Sortuj data wygenerowania", condition: "in", type: "radio", forceField: "sort_delivery.data.pickupCreateDate", options: [
                                                    {
                                                        value: "asc",
                                                        label: "Rosnąco"
                                                    }, {
                                                        value: "desc",
                                                        label: "Malejąco"
                                                    }
                                                ]
                                            },
                                            {label: "Stworzono", condition: "between", type: "date", forceField: "delivery.data.pickupCreateDate"},
                                        ],
                                        render: row => (
                                            <div>
                                                {formatDate(row.delivery.data.pickupCreateDate).dateTimeSystem}
                                            </div>
                                        )
                                    },
                                    {
                                        field: "shippingToTime",
                                        label: "Wysyłka do",
                                        filters: [
                                            {label: "Wysyłka do", condition: "between", type: "date", forceField: "shippingToTime"},
                                        ],
                                        center: true,
                                        render: row => <div>{row.shippingToTime ? formatDate(row.shippingToTime).dateNow : "---"}</div>
                                    },
                                    {
                                        field: "shippingMethodId",
                                        label: "Metoda wysyłki",
                                        center: true,
                                        render: row => (
                                            (this.state.shippingMethods && this.state.shippingMethods[row.shippingMethodId]) &&
                                                <div>
                                                    {this.state.shippingMethods[row.shippingMethodId].name}
                                                </div>
                                        ),
                                        filters: [
                                            {label: "Metoda dostawy", condition: "in", type: "checkbox", forceField: "delivery.type", options: [
                                                    {
                                                        value: "dpd",
                                                        label: "DPD"
                                                    },
                                                    {
                                                        value: "inpost",
                                                        label: "Inpost"
                                                    },
                                                    {
                                                        value: "ruch",
                                                        label: "Ruch"
                                                    },
                                                    {
                                                        value: "gls",
                                                        label: "GLS"
                                                    },
                                                    {
                                                        value: "baselinker",
                                                        label: "Allegro - Baselinker"
                                                    },
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        field: "numberMM",
                                        label: "Numer listu przewozowego",
                                        filters: [
                                            {label: "Pickup", condition: "like", forceField: "delivery.data.pickupNumber", type: "text"},
                                        ],
                                        center: true,
                                        render: row => (
                                            <div>
                                                {row.delivery.data.pickupNumber ?? row.delivery.pickupNumber}
                                            </div>
                                        )
                                    },
                                    {
                                        field: "brand",
                                        label: "Brand",
                                        center: true,
                                        filters: [
                                            {
                                                label: "Brand",
                                                condition: "in",
                                                type: "checkbox",
                                                forceField: "brand",
                                                options: [
                                                    {
                                                        value: "esotiq",
                                                        label: "Esotiq"
                                                    },
                                                    {
                                                        value: "henderson",
                                                        label: "Henderson"
                                                    },
                                                    {
                                                        value: "finalsale",
                                                        label: "Finalsale"
                                                    },
                                                    {
                                                        value: "vosedo",
                                                        label: "Vosedo"
                                                    }
                                                ]
                                            }
                                        ],
                                        render: row => (
                                            <div style={{textTransform: "capitalize"}}>
                                                {row.brand}
                                            </div>
                                        )
                                    },
                                    {
                                        field: "id",
                                        label: "Numer zamówienia",
                                        center: true,
                                        filters: [
                                            {label: "Numer zamówienia", condition: "in", type: "in"},
                                        ],
                                        render: row => (
                                            <div>
                                                {row.id}
                                            </div>
                                        )
                                    },
                                ]}
                            />
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => {
                            this.generateProtocol();
                            this.setState({generateProtocol: false, generateProtocolData: null})
                        }} positive>Generuj</Button>

                        <Button onClick={() => {
                            this.generateProtocol(true);
                            this.setState({generateProtocol: false, generateProtocolData: null})
                        }} positive>Generuj Finalsale</Button>

                        <Button onClick={() => {
                            this.generateBaselinkerProtocol()
                            this.setState({generateProtocol: false, generateProtocolData: null})
                        }} positive>Generuj Baselinker</Button>

                        <Button onClick={() => this.setState({generateProtocol: false, generateProtocolData: null, generateProtocolDataSelected: []})} negative>Zamknij</Button>
                    </Modal.Actions>
                </Modal>
                }

                {this.state.glsProtocol &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.glsProtocol} onClose={() => this.setState({glsProtocol: false, generateProtocolData: null, generateProtocolDataSelected: []})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content scrolling>
                            <div>
                                <Breadcrumb
                                    crumbs={[
                                        {
                                            label: `Protokół przekazania ${this.state.dataProtocolCount ? `(${this.state.dataProtocolCount})` : ""}`
                                        }
                                    ]}
                                />

                                <UITable
                                    dataFetch={{
                                        url: "/api/orders/delivery/getGlsProtocols",
                                        options: {
                                            limit: 25, currentPage: 1, filter: [
                                                {
                                                    label: "reklamacje",
                                                    condition: "exists",
                                                    value: 0,
                                                    field: "complaints"
                                                },
                                                {
                                                    label: "zamóienia salonowe",
                                                    condition: "exists",
                                                    value: 0,
                                                    field: "orderServices"
                                                },
                                            ],
                                        },
                                        callback: (result, count) => {
                                            this.setState({
                                                dataProtocolCount: count
                                            })
                                        }
                                    }}
                                    onPage={50}
                                    ref={table => this.protocolTable = table}
                                    // selectable={true}
                                    // onRowSelect={selected => {
                                    //     if (this.state.generateProtocolDataSelected.includes(selected)) {
                                    //         this.state.generateProtocolDataSelected = this.state.generateProtocolDataSelected.filter(e => e !== selected)
                                    //     } else {
                                    //         this.state.generateProtocolDataSelected.push(selected)
                                    //     }
                                    // }}
                                    provider={{
                                        setState: value => true
                                    }}
                                    columns={[
                                        {
                                            field: "createdAt",
                                            label: "Stworzono",
                                            center: true,
                                            filters: [
                                                {label: "Sortuj data wygenerowania", condition: "in", type: "radio", forceField: "createdAt", options: [
                                                        {
                                                            value: "asc",
                                                            label: "Rosnąco"
                                                        }, {
                                                            value: "desc",
                                                            label: "Malejąco"
                                                        }
                                                    ]
                                                },
                                                {label: "Stworzono", condition: "between", type: "date", forceField: "createdAt"},
                                            ],
                                            render: row => (
                                                <div>
                                                    {formatDate(row.createdAt).dateTimeSystem}
                                                </div>
                                            )
                                        },
                                        {
                                            field: "pickupId",
                                            label: "Numer protokołu",
                                            center: true,
                                            render: row => (
                                                <div>
                                                    {row.pickupId}
                                                </div>
                                            ),
                                            filters: [
                                                {label: "Pickup", condition: "in", forceField: "pickupId", type: "in"},
                                            ],
                                        },
                                        {
                                            field: "orders",
                                            label: "Liczba zamówień w protokole",
                                            center: true,
                                            render: row => (
                                                <div>
                                                    {row.orders.length}
                                                </div>
                                            )
                                        },
                                        {
                                            label: "Akcje",
                                            center: true,
                                            render: row => (
                                                <div>
                                                    <Button onClick={()=>{
                                                        api().get(`/api/orders/delivery/getProtocolReceipt/${row.pickupId}/gls?parlour=${DATA_OBJECT.selected_parlour}`)
                                                        .then(r => {
                                                            if(!r.data || !r.data.protocol || !r.data.protocol.data) {
                                                                return SnackBar("Błąd wygenerowania protokołu", "error")
                                                            }
                                                            const arrayBuffer = Uint8Array.from(r.data.protocol.data).buffer;

                                                            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                                                            const blobUrl = URL.createObjectURL(blob);
                                                            window.open(blobUrl, "_blank")
                                                        })

                                                    }}> Pobierz protokół</Button>
                                                </div>
                                            )
                                        },
                                    ]}
                                />
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({glsProtocol: false, generateProtocolData: null, generateProtocolDataSelected: []})} negative>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.commnetModal &&
                    <Modal dimmer={"inverted"} open={this.state.commnetModal}
                           onClose={() => this.setState({commnetModal: false, orderData: null})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div>
                                <Paper>
                                    <PaperTitle title={"Komentarze " + this.state.commnetModal.id}/>

                                    {this.state.commnetModal.comments ?
                                        <Comment.Group>
                                            {this.state.commnetModal.comments.map((comment, key) => {
                                                return (
                                                    <Comment key={key}>
                                                        <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/matt.jpg' />
                                                        <Comment.Content>
                                                            <Comment.Author as='a'>{comment.user}</Comment.Author>
                                                            <Comment.Metadata>
                                                                <div>{formatDate(comment.timestamp).dateTimeSystem}</div>
                                                            </Comment.Metadata>
                                                            <Comment.Text>{comment.value}</Comment.Text>
                                                            <Comment.Actions>
                                                                <Comment.Action
                                                                    onClick={() => {
                                                                        this.removeComment(comment)

                                                                        this.setState({
                                                                            commnetModal: false,
                                                                            orderData: null
                                                                        })
                                                                    }}
                                                                >{TranslationController.translate("Usuń")}</Comment.Action>
                                                            </Comment.Actions>
                                                        </Comment.Content>
                                                    </Comment>
                                                )
                                            })}
                                        </Comment.Group>
                                        : <div style={{
                                            textAlign: "center",
                                            color: "#c6c6c6",
                                            margin: 20
                                        }}>{TranslationController.translate("Jeszcze nikt nie dodał żadnego komentarza")}</div>
                                    }


                                    <Form
                                        reply
                                        onSubmit={(value) => {
                                            this.addComment()

                                            this.setState({
                                                commnetModal: false,
                                                orderData: null
                                            })
                                        }}
                                    >
                                        <Form.TextArea
                                            placeholder={TranslationController.translate("Treść...")}
                                            onChange={(e, v) => {
                                                this.state.newCommentValue = e.target.value;
                                            }}
                                        />
                                        <Button content={TranslationController.translate("Dodaj komentarz")} labelPosition='left' icon='edit' primary />
                                    </Form>
                                </Paper>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({commnetModal: false, orderData: null})} negative>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.changeStatus &&
                <Modal dimmer={"inverted"} size={"mini"} open={this.state.changeStatus} onClose={() => {
                    this.setState({changeStatus: false});
                }}>
                    <Modal.Header>{TranslationController.translate("Zmień status zamówienia")}</Modal.Header>
                    <Modal.Content>

                        <EsotiqInput
                            label={"Status"}
                            options={new OrderStatusesHelper().baseStatuses.map(e => (
                                {
                                    key: e.id, value: e.id, text: TranslationController.translate(e.label)
                                }
                            ))}
                            onChange={(value) => {
                                this.state.changeStatus.status = value;
                            }}
                        />

                        <React.Fragment>
                            <EsotiqInput
                                label={"Kompletacja"}
                                options={new OrderStatusesHelper().completationStatuses.map(e => (
                                    {
                                        key: e.id, value: e.id, text: TranslationController.translate(e.label)
                                    }
                                ))}
                                onChange={(value) => {
                                    this.state.changeStatus.statusCompletation = value;
                                }}
                            />

                            <EsotiqInput
                                label={"GATE"}
                                options={new OrderStatusesHelper().gateStatuses.map(e => (
                                    {
                                        key: e.id, value: e.id, text: TranslationController.translate(e.label)
                                    }
                                ))}
                                onChange={(value) => {
                                    if (value === "null") value = null;

                                    this.state.changeStatus.gateStatus = value;
                                }}
                            />
                        </React.Fragment>

                        <EsotiqInput
                            label={"Wysyłka"}
                            options={new OrderStatusesHelper().shippingStatuses.map(e => (
                                {
                                    key: e.id, value: e.id, text: TranslationController.translate(e.label)
                                }
                            ))}
                            onChange={(value) => {
                                this.state.changeStatus.statusShipping = value;
                            }}
                        />

                        <React.Fragment>
                            <EsotiqInput
                                label={"Sync"}
                                options={new OrderStatusesHelper().synchroStatuses.map(e => (
                                    {
                                        key: e.id, value: e.id, text: TranslationController.translate(e.label)
                                    }
                                ))}
                                onChange={(value) => {
                                    this.state.changeStatus.statusSynchro = value;
                                }}
                            />
                        </React.Fragment>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => {
                            this.setState({changeStatus: false});
                        }} negative>Anuluj</Button>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Zapisz'
                            onClick={() => {

                                Confirm("Czy na pewno chcesz zmienić status zamówień?", null, () => {
                                    this.setState({
                                        loading: true
                                    });

                                    api(true).post("/api/orders/changeMultiStatus", {filters: this.orderTable.state.appliedFilters, internalOrders: this.state.internalOrders, statuses: this.state.changeStatus, userId: DATA_OBJECT.user_login})
                                        .then(r => {
                                            this.setState({changeStatus: false});

                                            this.setState({
                                                loading: false,
                                                tableSelectableState: [],
                                            });

                                            if (r.data.error) {
                                                SnackBar(r.data.message, "error");
                                            } else {
                                                SnackBar("Zaktualizowano zamówienia");

                                                this.orderTable.setState({
                                                    data: null,
                                                });

                                                this.orderTable.init()
                                            }
                                        })
                                })
                            }}
                        />
                    </Modal.Actions>
                </Modal>
                }

                {this.state.modalTimingReport &&
                    <Modal dimmer={"inverted"} size={"mini"} open={this.state.modalTimingReport} onClose={() => this.setState({modalTimingReport: false})}>
                        <Modal.Header>Generuj raport czasowy</Modal.Header>
                        <Modal.Content>
                            <div className={"date-picker-wrapper"}>
                                <EsotiqInput
                                    width={"100%"}
                                    label={"Wybierz zakres"}
                                    options={[{
                                        key: "sent_date",
                                        value: "sent_date",
                                        text: "Data wysłania"
                                    }, {
                                        key: "created_date",
                                        value: "created_date",
                                        text: "Data utworzenia"
                                    }]}
                                    defValue={this.state.timingReportRange}
                                    placeholder={"Wybierz opcję"}
                                    onChange={v => {
                                        this.setState({
                                            timingReportRange: v
                                        })
                                    }}
                                />

                                <EsotiqInput
                                    width={"100%"}
                                    label={"Wybierz typ"}
                                    options={[{
                                        key: "type_parlour",
                                        value: "type_parlour",
                                        text: "Salonowe"
                                    }, {
                                        key: "type_internal",
                                        value: "type_internal",
                                        text: "Wewnętrzne"
                                    }, {
                                        key: "type_internal_new",
                                        value: "type_internal_new",
                                        text: "Wewnętrzne  nowe"
                                    }]}
                                    defValue={this.state.timingReportType}
                                    placeholder={"Wybierz opcję"}
                                    onChange={v => {
                                        this.setState({
                                            timingReportType: v
                                        })
                                    }}
                                />

                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Data od"
                                        format="dd-MM-yyyy"
                                        value={this.state.timingReportFrom}
                                        onChange={(date) => {
                                            const filterValue = formatDate(date).dateNowSystem

                                            this.setState({
                                                timingReportFrom: filterValue
                                            })
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Data do"
                                        format="dd-MM-yyyy"
                                        value={this.state.timingReportTo}
                                        onChange={(date) => {
                                            const filterValue = formatDate(date).dateNowSystem

                                            this.setState({
                                                timingReportTo: filterValue
                                            })
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>

                                {this.state.parloursList &&
                                <EsotiqInput
                                    label={"Salon"}
                                    multiple={true}
                                    options={this.state.parloursList}
                                    onChange={(value) => this.setState({timingReportParlour: value})}
                                />
                                }

                                <EsotiqInput
                                    label={"Status"}
                                    multiple={true}
                                    options={[
                                        {key: "0", value: "new", text: "Nowe"},
                                        {key: "1", value: "accepted", text: "Zaakceptowano"},
                                        {key: "2", value: "rejected", text: "Odrzucono"},
                                        {key: "3", value: "send", text: "Wysłano"}
                                    ]}
                                    onChange={(value) => this.setState({timingReportStatus: value})}
                                />

                                <EsotiqInput
                                    label={"Wyklucz E99"}
                                    options={[
                                        {key: true, text: "Tak", value: true},
                                        {key: false, text: "Nie", value: false}
                                    ]}
                                    placeholder={"Wybierz opcję"}
                                    onChange={(value) => this.setState({timingReportExcludeE99: value})}
                                />

                                <EsotiqInput
                                    label={"Wyklucz nieopłacone"}
                                    options={[
                                        {key: true, text: "Tak", value: true},
                                        {key: false, text: "Nie", value: false}
                                    ]}
                                    placeholder={"Wybierz opcję"}
                                    onChange={(value) => this.setState({timingReportExcludeNotPaid: value})}
                                />

                                <div>
                                    <Button
                                        style={{
                                            marginBottom: 25,
                                            marginTop: 20
                                        }}
                                        color='blue'
                                        content='Pobierz'
                                        icon='download'
                                        label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                        onClick={() => {
                                            if (!this.state.timingReportFrom) {
                                                return SnackBar("Wybierz datę od", "error")
                                            }

                                            if (!this.state.timingReportTo) {
                                                return SnackBar("Wybierz datę do", "error")
                                            }

                                            this.setState({
                                                loading: true
                                            });

                                            api().post("/api/orderService/timingReport", {
                                                from: this.state.timingReportFrom,
                                                to: this.state.timingReportTo,
                                                parlour: this.state.timingReportParlour,
                                                status: this.state.timingReportStatus,
                                                range: this.state.timingReportRange,
                                                type: this.state.timingReportType,
                                                excludeE99: this.state.timingReportExcludeE99 || false,
                                                excludeNotPaid: this.state.timingReportExcludeNotPaid || false
                                            }).then(res => {
                                                this.setState({
                                                    loading: false
                                                })

                                                if (res && res.data && res.data.length) {
                                                    Download(res.data, "TimingReport.csv")
                                                } else {
                                                    SnackBar("Brak danych", "warning")
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </Modal.Content>
                    </Modal>
                }

                {this.state.loading &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>{TranslationController.translate("Proszę czekać... trwa synchronizacja zamówień.")}</Loader>
                    </Dimmer>
                }
            </React.Fragment>
        )
    }
}

export default Orders
