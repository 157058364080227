import React from "react"
import {api} from "../../lib/axios";
import {Icon, Loader, Table, Input, Modal} from 'semantic-ui-react'
import SnackBar from "../../components/Snack";
import Button from "../../components/Button";
import {TranslationController} from "../../controller/menager";
import {DATA_OBJECT} from "../../controller/dataController";
import GiftCardRechargePopup from "../../components/GiftCardRechargePopup";

export default class WarehouseOrderServiceEANScan extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scanData: null
        }
    }

    setScanData() {
        this.props.setOrderScanningController(this);

        if (!this.props.sending) {
            return SnackBar("Brak danych o towarach do wysyłki, zgłoś problem do operatora.", "error")
        }

        if (!this.props.sending[0]) {
            return SnackBar("Brak danych o towarach do wysyłki, zgłoś problem do operatora.", "error")
        }

        let isEanMissingOnAny = false;

        this.props.sending.forEach(p => {
            if (!p.ean) {
                isEanMissingOnAny = true;
            }
        })
        if (isEanMissingOnAny) {
            const sending = this.props.sending;
            const products = [];

            sending.map(item => products.push(item.sku))

            api().post("/api/products/getProductsBySku", {index: products})
                .then(r => {
                    const serverProducts = r.data.data;

                    if (serverProducts) {
                        this.props.sending.map(item => {
                            serverProducts.forEach(product => {
                                if (item.sku.includes(product.model)) {
                                    item.name = product.name;

                                    if (product.images && product.images[0]) {
                                        item.img = product.images[0].file
                                    }
                                }
                            })
                        })
                    }

                    return api().post("/api/products/getEan", {sku: products, limit: 9999, currentPage: 1})
                })
                .then(r => {
                    let eans = r.data.data;
                    const eanMap = {};

                    eans.forEach(ean => eanMap[ean.sku] = ean);

                    this.props.sending.map(item => {
                        if (!eanMap[item.sku]) {
                            return SnackBar(`Nie znaleziono kodu EAN dla jednego z produktów (${item.sku}). Zgłoś do operatora.`, "error")
                        }

                        item.ean = eanMap[item.sku].ean;
                        item.scanned = 0;
                    })
                    this.setState({
                        scanData: this.props.sending
                    }, () => {
                        this.onScanner();
                    })
                })
        } else {
            this.setState({
                scanData: this.props.sending
            }, () => {
                this.checkSuccess();
                this.onScanner();
            })
        }
    }

    componentDidMount() {
        this.setScanData();
    }

    isSendingEqualToPevProps(newSending, prevSending) {
        if (newSending.length !== prevSending.length) {
            return false;
        }

        let isEqual = true;

        newSending.forEach((item, key) => {
            if (item.sku !== prevSending[key].sku) {
                isEqual = false;
            } else if (item.qty !== prevSending[key].qty) {
                isEqual = false;
            }
        })

        return isEqual;

    }

    componentDidUpdate(prevProps) {
        if(!this.isSendingEqualToPevProps(this.props.sending, prevProps.sending)) {
            this.setScanData();
        }
    }

    reset() {
        this.state.scanData.forEach(e => {
            e.scanned = 0;
        })

        this.setState({
            refresh: new Date().getTime()
        })
    }

    scan(ean) {
        let dataItem = this.state.scanData.find(e => e.ean === ean.toString())

        if (!dataItem) {
            return SnackBar("Nie znaleziono towaru. Niepoprawny kod EAN.", "error")
        }

        if (dataItem.scanned == dataItem.qty) {
            return SnackBar("Zeskanowałeś już wystarczającą ilość towaru.", "warning")
        }

        dataItem.scanned = dataItem.scanned + 1

        this.setState({
            scanData: this.state.scanData
        })

        if ((dataItem.scanned == dataItem.qty) || (dataItem.scanned + 1 == dataItem.qty)) {
            this.checkSuccess()
            this.scanUpdate()

            return SnackBar("Zeskanowano poprawnie wszystkie towary z indeksu.")
        } else {
            this.scanUpdate()

            return SnackBar("Zeskanowano poprawnie.")
        }
    }

    scanUpdate() {
        this.setState({
            scanProcess: true
        })

        api().post("/api/orderService/scanUpdate", {
            id: this.props.orderServiceId,
            orderId: this.props.orderId,
            parlour: this.props.parlour,
            orderService: this.state.scanData
        })
            .then(r => {
                this.setState({
                    scanProcess: false
                })
            })
    }

    checkSuccess() {
        let isSuccess = true

        this.state.scanData.forEach(item => {
            if (item.scanned < item.qty) {
                isSuccess = false;
            }
        })

        if (isSuccess) {
            this.props.onSuccess()
        }
    }

    onScanner() {
        let isEvent = false;
        let input = document.getElementById("scan-input");

        input.addEventListener("focus", () => {
            if (!isEvent) {
                isEvent = true;

                input.addEventListener("keypress", (e) => {
                    setTimeout(() => {
                        if (e.keyCode == 13) {
                            this.scan(input.value);
                            input.select();
                        }
                    }, 500)
                })
            }
        });

        document.addEventListener("keypress", (e) => {
            if (e.target.tagName !== "INPUT") {
                input.focus();
            }
        });
    }

    getBaseCartItems() {
        return this.props.sending.filter(item => {
            const cartItem = this.props.orderDetails.items.find(cartItem => cartItem.sku === item.sku);
            if (!cartItem) return true;

            return !cartItem.isGiftCard;
        });
    }

    getGiftCardItems() {
        return this.props.sending.filter(item => {
            const cartItem = this.props.orderDetails.items.find(cartItem => cartItem.sku === item.sku);

            if (cartItem) {
                item.isGiftCard = cartItem.isGiftCard;
                item.isVirtualCard = cartItem.isVirtualCard;
            }

            if (!cartItem) return false;

            return cartItem.isGiftCard;
        });
    }

    getCartItemsToRender() {
        const baseItems = this.getBaseCartItems();
        const giftCardItems = this.getGiftCardItems();

        return {baseItems, giftCardItems};
    }

    render() {
        const {baseItems, giftCardItems} = this.getCartItemsToRender();
        return (
            <div style={{marginTop: 10, width: "100%", position: "relative"}}>
                {this.props.disabled &&
                    <div className={"eanScan-disabled"}></div>
                }
                {(this.state.scanData) ?
                    <React.Fragment>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <Input
                                action={{
                                    color: 'teal',
                                    labelPosition: 'right',
                                    icon: 'tag',
                                    content: TranslationController.translate("Zatwierdź"),
                                    onClick: () => this.scan(this.state.givenEan)
                                }}
                                placeholder={TranslationController.translate("Wprowadź EAN")}
                                onChange={(e, v) => {this.setState({
                                    givenEan: v.value
                                })}}
                                id={"scan-input"}
                            />
                        </div>


                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell>{TranslationController.translate("SKU")}</Table.HeaderCell>
                                    <Table.HeaderCell>{TranslationController.translate("")}</Table.HeaderCell>
                                    <Table.HeaderCell>{TranslationController.translate("Nazwa")}</Table.HeaderCell>
                                    <Table.HeaderCell>{TranslationController.translate("Ilość")}</Table.HeaderCell>
                                    <Table.HeaderCell>{TranslationController.translate("Stan mag.")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {baseItems.map((item, key) => {
                                    if (item.qty < 1) return;

                                    return (
                                        <Table.Row style={(this.props.stockMap[item.sku] ?? 0) < item.qty ? {
                                            background: "rgba(255,0,59,0.34)"
                                        } : {}}>
                                            <Table.Cell
                                                negative={item.scanned < item.qty}
                                                positive={item.scanned === item.qty}
                                                warning={item.scanned > item.qty}
                                            >
                                                <Icon name={item.scanned >= item.qty ? "checkmark" : "close"}/>
                                                {TranslationController.translate("Zeskanowano")}: {item.scanned}/{item.qty}
                                            </Table.Cell>
                                            <Table.Cell>{item.sku}</Table.Cell>
                                            <Table.Cell
                                                style={{
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        imageModal: `${item.img}`
                                                    })
                                                }}
                                            >
                                                {item.img ?
                                                    <img
                                                        style={{
                                                            width: 60
                                                        }}
                                                        src={`${item.img}`}
                                                        alt=""
                                                    /> : "---"}
                                            </Table.Cell>
                                            <Table.Cell>{DATA_OBJECT["user"] && (DATA_OBJECT["user"]["country"] === "pl" ? item.name : (DATA_OBJECT["user"]["country"] === "de" && item.deName) ? item.deName : item.sku)}</Table.Cell>
                                            <Table.Cell>{item.qty}</Table.Cell>
                                            <Table.Cell>{this.props.stockMap[item.sku] === undefined ? "---" : this.props.stockMap[item.sku]}</Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>

                        {this.props.previouslyScanned &&
                            <Button
                                onClick={() => this.props.reset()}
                            >
                                {TranslationController.translate("Zresetuj zeskanowane")}
                            </Button>
                        }

                        {this.state.imageModal &&
                            <Modal dimmer={"inverted"} size={"medium"} open={this.state.imageModal}
                                   onClose={() => this.setState({imageModal: false})}>
                                <Modal.Header></Modal.Header>
                                <Modal.Content>
                                    <div>
                                        <img style={{
                                            maxWidth: 500,
                                            margin: "0 auto",
                                            display: "block"
                                        }} src={this.state.imageModal} alt=""/>
                                    </div>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button onClick={() => this.setState({imageModal: false})} negative>Zamknij</Button>
                                </Modal.Actions>
                            </Modal>
                        }

                    </React.Fragment> : <div><Loader active></Loader></div>
                }
            </div>
        );
    }
}
